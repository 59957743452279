import * as React from "react";

import {
  ArgumentAxis,
  Legend,
  Series,
  Chart,
  ValueAxis,
  ConstantLine,
} from "devextreme-react/chart";
import { observer, inject } from "mobx-react";
class O2SaturationPulseChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
    };
  }

  customizeTooltip = (pointInfo) => {
    console.log("pointInfo", pointInfo);
    return {
      text: `Date: ${pointInfo.argumentText}<br/>Value: ${pointInfo.valueText}<br/>Time: ${pointInfo.point.data.time.slice(11,16)}`
    };
  };

  render() {
    const { centralStore } = this.props;

    return (
      <>
        {centralStore.o2SaturationChartDataValue &&
        centralStore.o2SaturationChartDataValue.length ? (
          <Chart
            adaptiveLayout={{
              keepLabels: false,
              height: 100,
            }}
            size={
              this.props.viewedFromTable
                ? { height: 280, width: 400 }
                : { height: 280 }
            }
            tooltip={{
              enabled: true,
              customizeTooltip: this.customizeTooltip,
            }}
            dataSource={centralStore.o2SaturationChartDataValue}
          >
            <Series
              type="splinearea"
              argumentField="timestamp"
              valueField="value"
              name="O2 Saturation "
            ></Series>
            <ValueAxis
              tick={{ visible: true }}
              tickInterval={20}
              showZero={true}
              width={2}
            >
              <ConstantLine
                width={1}
                value={93}
                label={{ visible: false }}
                color="#f64866"
              ></ConstantLine>
              <ConstantLine
                width={1}
                value={100}
                label={{ visible: false }}
                color="#f64866"
              ></ConstantLine>
            </ValueAxis>
            <ArgumentAxis
              title={
                "Showing " +
                centralStore.o2SaturationChartDataValue.length +
                " O2 Readings"
              }
              width={2}
              valueMarginsEnabled={false}
            />
            <Legend
              visible={true}
              font={{ size: 16 }}
              markerSize={10}
              margin={{ top: 5 }}
              verticalAlignment="bottom"
              rowItemSpacing={2}
              columnItemSpacing={2}
              horizontalAlignment="center"
              position="outside"
            />
          </Chart>
        ) : (
          <h2>No data fetched. Consider Changing filter..!</h2>
        )}
      </>
    );
  }
  componentWillUnmount = () => {
    this.setState({ data: [] });
  };
}
export default inject("centralStore")(observer(O2SaturationPulseChart));
