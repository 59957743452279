import React, { Component } from "react";
import logo from "../../assets/images/MVS-Logo-dark.png";
import TextField from "@material-ui/core/TextField";
import { withRouter, Link } from "react-router-dom";
import Footer from "../Footer";
import { Auth } from "aws-amplify";
import { inject, observer } from "mobx-react";
import { checkAllFieldsValid } from "../../common/util";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/util";
import { v4 } from "uuid";
document.body.classList.add("bodyStyle");

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      terms: false,
      disabled: true,
      errorMessage: null,
      signupError: false,
      signupSuccess: false,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ errorMessage: null });
    const { centralStore } = this.props;
    const { firstName, lastName, email, password } = this.state;
    if (!this.state.errorMessage) {
      if (
        checkAllFieldsValid({ firstName, lastName, email, password }) === true
      ) {
        centralStore.showAuthLoader();
        await await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email: email,
            name: firstName + " " + lastName,
            "custom:userId": v4(),
            "custom:role": "physician",
            "custom:profileImage": "NA",
            "custom:phone": "Not Provided Yet",
          },
        })
          .then(async (userResponse) => {
            this.setState({
              signupSuccess: true,
              firstName: "",
              lastName: "",
              email: "",
              password: "",
            });
            centralStore.hideAuthLoader();
          })
          .catch((error) => {
            console.log(error.message);
            this.setState({ errorMessage: error.message });
            centralStore.hideAuthLoader();
            this.setState({ signupSuccess: false });
          });
      } else {
        this.setState({ signupError: true });
      }
    }
    console.log(
      "Validation",
      !this.state.email.length && this.state.signupError
    );
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: null,
      signupError: false,
    });
  };

  render() {
    const { classes, centralStore } = this.props;

    return (
      <>
        <Backdrop
          className={classes.backdrop}
          open={centralStore.currentAuthloaderValue}
          onClick={() => {}}
        >
          <CircularProgress className={classes.backdrop} color="inherit" />
        </Backdrop>
        <div className="login-wrapper">
          <div className="login-box-outer">
            <a href="/" className="login-logo">
              <img src={logo} alt="" className="img-fluid" />
            </a>
           <div className="login-box white-radius-box">
             <h5 className="box-heading text-center">Sign Up</h5>
             {this.state.errorMessage ? (
               <Alert severity="error">{this.state.errorMessage} </Alert>
             ) : null}
             {this.state.signupSuccess ? (
               <Alert severity="success">
                 Signup Successful ..! A confirmation Link has been sent at you
                 given email address...!
               </Alert>
             ) : (
               <div className="login-fields">
                 <div className="form-field">
                   <TextField
                     error={
                       !this.state.firstName.length && this.state.signupError
                     }
                     helperText={
                       !this.state.firstName.length && this.state.signupError
                         ? "Field Cannot be Empty"
                         : ""
                     }
                     required
                     name="firstName"
                     type="text"
                     label="First Name"
                     fullWidth={true}
                     value={this.state.firstName}
                     onChange={this.onInputChange}
                   />{" "}
                 </div>
                 <div className="form-field">
                   <TextField
                     error={
                       !this.state.lastName.length && this.state.signupError
                     }
                     helperText={
                       !this.state.lastName.length && this.state.signupError
                         ? "Field Cannot be Empty"
                         : ""
                     }
                     autoFocus
                     required
                     name="lastName"
                     type="text"
                     label="Last Name"
                     fullWidth={true}
                     value={this.state.lastName}
                     onChange={this.onInputChange}
                   />
                 </div>
                 <div className="form-field">
                   <TextField
                     error={!this.state.email.length && this.state.signupError}
                     helperText={
                       !this.state.email.length && this.state.signupError
                         ? "Field Cannot be Empty"
                         : ""
                     }
                     required
                     name="email"
                     type="text"
                     label="Email"
                     fullWidth={true}
                     value={this.state.email}
                     onChange={this.onInputChange}
                   />
                 </div>
                 <div className="form-field">
                   <TextField
                     error={
                       !this.state.password.length && this.state.signupError
                     }
                     helperText={
                       !this.state.password.length && this.state.signupError
                         ? "Field Cannot be Empty"
                         : ""
                     }
                     required
                     name="password"
                     type="password"
                     label="Password"
                     fullWidth={true}
                     value={this.state.password}
                     onChange={this.onInputChange}
                   />
                 </div>
               </div>
             )}
             {!this.state.signupSuccess ? (
               <div>
                 <button
                   className="btn btn-primary"
                   onClick={this.handleSubmit}
                 >
                   Sign Up
                 </button>
               </div>
             ) : null}
             <p className="already-member">
               {!this.state.signupSuccess
                 ? "Already a Member?"
                 : "Continue To "}
               <Link to="/">Login</Link>
             </p>
           </div>
              
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
export default withRouter(
  withStyles(styles)(inject("centralStore")(observer(Signup)))
);
