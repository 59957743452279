import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="copy-right">
          © copyright 2020 <Link to="/home">MVS</Link>
        </div>
      </footer>
    );
  }
}
