import { authorizeHeader } from "../util";
export const get = (body) => {
  let authHeader = authorizeHeader(body.headers);
  // console.log("GET API URL===>", body.url);
  return fetch(body.url, {
    method: "GET",
    headers: authHeader || {},
  });
};

export const post = (body) => {
  let authHeader = authorizeHeader(body.headers);
  // console.log("POST API URL===>", body.url);
  return fetch(body.url, {
    method: "POST",
    headers: authHeader || {},
    body: JSON.stringify(body.data),
  });
};

export const put = (body) => {
  let authHeader = authorizeHeader(body.headers);
  // console.log("PUT API URL===>", body.url);
  return fetch(body.url, {
    method: "PUT",
    headers: authHeader,
    body: JSON.stringify(body.data),
  });
};

export const deleteapi = (body) => {
  let authHeader = authorizeHeader(body.headers);
  return fetch(body.url, {
    method: "DELETE",
    headers: authHeader,
    body: JSON.stringify(body.data),
  });
};
