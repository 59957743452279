import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import userImg from "../../assets/images/avtar.png";
import { observer, inject } from "mobx-react";
import { TextField } from "@material-ui/core";
import { Auth, Storage } from "aws-amplify";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import { updatePatientProvider } from "../../common/apicall/patient";

export class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      profileImageKey: "NA",
      profileImageFile: undefined,
      imageName: "",
      profileImageUrl: "NA",
      physicianId: "",
      imageChange: false,
      qualification: "",
    };
  }

  onEditClick = () => {
    this.setState({ disabled: false });
  };

  onSaveChanges = async () => {
    const { centralStore } = this.props;
    centralStore.showLoader();

    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(async (authenticatedUser) => {
        console.log("Auth User====>", this.state.profileImageUrl);

        let attributes = {};
        if (this.state.imageChange === false) {
          attributes = {
            "custom:phone": this.state.phone,
            "custom:qualification": this.state.qualification,
            name: this.state.firstName + " " + this.state.lastName,
          };
          await Auth.updateUserAttributes(authenticatedUser, attributes);
          await Auth.currentAuthenticatedUser({
            bypassCache: true,
          }).then(async (authenticatedUserUpdated) => {
            await updatePatientProvider(authenticatedUserUpdated.attributes);
            window.location.reload();
          });
        } else {
          console.log("Image Change Update====>");
          this.uploadFileToS3(authenticatedUser);
        }
        centralStore.hideLoader();
      })
      .catch((error) => {
        console.log("Error auth", error);
        centralStore.hideLoader();
      });
  };

  uploadFileToS3 = async (user) => {
    await Storage.put(
      `physician/profile/${this.state.physicianId}/${this.state.imageName}`,
      this.state.profileImageFile
    )
      .then(async (result) => {
        console.log("Respnse===>", result);
        this.setState({ disabled: true });
        let attributes = {
          "custom:profileImage": result.key,
          "custom:phone": this.state.phone,
          name: this.state.firstName + " " + this.state.lastName,
        };
        await Auth.updateUserAttributes(user, attributes);
        window.location.reload();
      })
      .catch((err) => {
        console.log("ERROR==>", err);
      });
  };
  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: null,
    });
  };
  onFileChange = (event) => {
    this.setState({
      profileImageFile: event.target.files[0],
      imageName: event.target.files[0].name,
      imageChange: true,
    });
    this.getBase64(event.target.files[0], (result) => {
      this.setState({
        profileImageUrl: result,
      });
    });
    console.log(event.target.files[0].name);
    // Update the state
  };
  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };
  componentDidUpdate = async (prevProps, prevState) => {
    const { centralStore } = this.props;

    if (this.props.currentUserInfo !== prevProps.currentUserInfo) {
      centralStore.showLoader();
      console.log(" this.props.currentUserInfo", this.props.currentUserInfo);
      this.setState({
        email: this.props.currentUserInfo.email,
        firstName: this.props.currentUserInfo.name.split(" ")[0],
        lastName: this.props.currentUserInfo.name.split(" ")[1],
        phone: this.props.currentUserInfo["custom:phone"],
        qualification: this.props.currentUserInfo["custom:qualification"],
        physicianId: this.props.currentUserInfo["custom:userId"],
      });
      await Storage.get(this.props.currentUserInfo["custom:profileImage"])
        .then((result) => {
          centralStore.showLoader();
          console.log("Get Respnse===>", result);
          if (
            this.props.currentUserInfo["custom:profileImage"] &&
            this.props.currentUserInfo["custom:profileImage"] !== "NA"
          ) {
            this.setState({
              profileImageUrl: result,
            });
          }
          centralStore.hideLoader();
        })
        .catch((err) => {
          console.log("Get ERROR==>", err);
          centralStore.hideLoader();
        });
    }
  };

  render() {
    return (
      <div className="content">
        <div className="my-profile">
          <div className="profile-inner">
            <div className="user-profile-img">
              <div className="user-img">
                <div className="img-box">
                  <img
                    src={
                      this.state.profileImageUrl === "NA"
                        ? userImg
                        : this.state.profileImageUrl
                    }
                    alt=""
                  />
                </div>
                <button
                  style={
                    this.state.disabled
                      ? { display: "none" }
                      : { display: "flex" }
                  }
                  className="btn-change-pic"
                >
                  <CameraAltOutlinedIcon />

                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.onFileChange}
                  />
                </button>
              </div>
            </div>
            <Grid
              container
              spacing={3}
              className="profile-info"
              justify="center"
            >
              <Grid item xs={12} md={6}>
                <div className="profile-field-box form-group">
                  <h5 className="label">First Name</h5>
                  <TextField
                    fullWidth
                    disabled={this.state.disabled}
                    name="firstName"
                    autoFocus={this.state.disabled}
                    className="profile-content"
                    value={this.state.firstName}
                    onChange={this.onInputChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="profile-field-box form-group">
                  <h5 className="label">Last Name</h5>
                  <TextField
                    fullWidth
                    disabled={this.state.disabled}
                    name="lastName"
                    autoFocus={this.state.disabled}
                    className="profile-content"
                    value={this.state.lastName}
                    onChange={this.onInputChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="profile-field-box  form-group">
                  <h5 className="label">Email</h5>
                  <TextField
                    fullWidth
                    disabled={true}
                    name="email"
                    className="profile-content"
                    value={this.state.email}
                    onChange={this.onInputChange}
                  />{" "}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="profile-field-box form-group">
                  <h5 className="label">Phone</h5>
                  <TextField
                    fullWidth
                    disabled={this.state.disabled}
                    name="phone"
                    className="profile-content"
                    value={this.state.phone}
                    onChange={this.onInputChange}
                  />{" "}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="profile-field-box form-group">
                  <h5 className="label">Qualification</h5>
                  <TextField
                    fullWidth
                    disabled={this.state.disabled}
                    name="qualification"
                    autoFocus={this.state.disabled}
                    className="profile-content"
                    value={this.state.qualification}
                    onChange={this.onInputChange}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <Box mt={3} textAlign="center">
            {this.state.disabled === true ? (
              <button className="btn btn-primary" onClick={this.onEditClick}>
                Edit Profile{" "}
              </button>
            ) : null}
            {this.state.disabled === false ? (
              <button className="btn btn-primary" onClick={this.onSaveChanges}>
                Save Changes
              </button>
            ) : null}
          </Box>
        </div>
      </div>
    );
  }
}

export default inject("centralStore")(observer(UserProfile));
