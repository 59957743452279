import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { getNextPhysicianData } from '../common/api-utils';
import Snackbar from '@material-ui/core/Snackbar';

const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#fff',
        color: '#151515',
        fontWeight: '600',
        lineHeight: '1.2',
        padding: '5px 16px',
        fontSize: '18px',
    },
    body: {
        fontSize: 18,
        fontWeight: '500',
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F8F8F8',
        },
    },
}))(TableRow);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

class ClientListsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pop_open: false,
            pop_chart_open: false,
            currentcontactItem: {},
            physicianData: [],
            anchorEl: null,
            anchorElMenu: null,
            openMenu: false,
            currentSelectedPhysician: undefined,
            number: 5,
            openMessageDialog: false,
            openPopup: false,
        };
    }

  handlePopupClose = () => {
      this.setState({ openPopup: false });
  };

  handleMenuClose = () => {
      this.setState({ anchorElMenu: null });
  };

  loadNextData = async () => {
      const { centralStore } = this.props;
      let key = JSON.parse(localStorage.getItem('lastphysicianevaluatedkey'));
      if (key) {
          console.log('103',key);
          centralStore.setHasMore(false);
      } else {
          centralStore.setHasMore(true);
          getNextPhysicianData();
      }
  };

  splitFirstLastName = (name) => {
      let firstName = name ? name.split(' ')[0] : '';
      let lastName = name ? name.split(' ')[1] : '';
      return `${lastName}, ${firstName}`;
  };

  render() {
      const { centralStore } = this.props;
      let physicianDataTable = centralStore.physicianTableDataValue || [];
      return (
          <>
              <Grid container className="table-grid-container">
                  <Grid item xs={12}>
                      <Paper className="shadow-box">
                          <Box p={2} className="box-heading">
                              <Typography variant="h4">Client Details</Typography>
                          </Box>
                          <TableContainer id="scrollableDiv" className="table-height">
                              <InfiniteScroll
                                  dataLength={physicianDataTable.length}
                                  next={this.loadNextData}
                                  hasMore={centralStore.currentHasMoreValue}
                                  loader={
                                      <Box
                                          display="flex"
                                          flexDirection="column"
                                          alignItems="center"
                                      >
                                          <CircularProgress className="loading-more-record" />
                    Loading More Records...
                                      </Box>
                                  }
                                  endMessage={
                                      <Box
                                          display="flex"
                                          flexDirection="column"
                                          alignItems="center"
                                          marginTop={2}
                                          marginBottom={2}
                                      >
                                          <Alert severity="info">
                                              {physicianDataTable.length
                                                  ? 'All ' +
                        physicianDataTable.length +
                        ' Records Fetched...!'
                                                  : 'No Records To Display...!'}
                                          </Alert>
                                      </Box>
                                  }
                                  scrollableTarget="scrollableDiv"
                              >
                                  <Table
                                      stickyHeader
                                      className="patient-tbl"
                                      aria-label="sticky table"
                                      size="small"
                                  >
                                      <TableHead>
                                          <TableRow>
                                              <StyledTableCell>Client Name</StyledTableCell>
                                              <StyledTableCell align="center">Speciality</StyledTableCell>
                                              <StyledTableCell align="center">Role</StyledTableCell>
                                              <StyledTableCell align="center">Email</StyledTableCell>
                                              <StyledTableCell align="center">Phone</StyledTableCell>
                                              <StyledTableCell align="center">Address</StyledTableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          <StyledTableRow className="hint-row">
                                              <StyledTableCell>Last Name, First Name</StyledTableCell>
                                              <StyledTableCell align="center">Speciality</StyledTableCell>
                                              <StyledTableCell align="center">Role</StyledTableCell>
                                              <StyledTableCell align="center">Email</StyledTableCell>
                                              <StyledTableCell align="center">1(805) 123-4567 </StyledTableCell>
                                              <StyledTableCell align="center">Address</StyledTableCell>
                                          </StyledTableRow>

                                          {physicianDataTable.map((physician, index) => {
                                              return (
                                                  <StyledTableRow key={index}>
                                                      <StyledTableCell>
                                                          <Link
                                                              onContextMenu={async (event) => {
                                                                  event.preventDefault();
                                                                  this.setState({
                                                                      anchorElMenu: event.currentTarget,
                                                                      currentSelectedPhysician: physician,
                                                                  });
                                                              }}
                                                              onClick={() => {
                                                                  localStorage.setItem(
                                                                      'physician_id',
                                                                      physician.physician_id
                                                                  );
                                                              }}
                                                              to="#"
                                                          >
                                                              {physician.lastName + ', ' + physician.firstName}
                                                          </Link>
                                                      </StyledTableCell>
                         
                                                 
                                                      <LightTooltip  title={
                                                          <React.Fragment>
                                                              {'Speciality'}
                                                          </React.Fragment>
                                                      }placement="top">
                                                          <StyledTableCell align="center">
                                                              {physician.speciality}
                                                          </StyledTableCell>
                                                      </LightTooltip>

                                                      <LightTooltip  title={
                                                          <React.Fragment>
                                                              {'Role'}
                                                          </React.Fragment>
                                                      }placement="top">
                                                          <StyledTableCell align="center">
                                                              {physician.role}
                                                          </StyledTableCell>
                                                      </LightTooltip>

                                                      <LightTooltip  title={
                                                          <React.Fragment>
                                                              {'Email'}
                                                          </React.Fragment>
                                                      }placement="top">
                                                          <StyledTableCell align="center">
                                                              {physician.email}
                                                          </StyledTableCell>
                                                      </LightTooltip>

                                                  
                                                      <StyledTableCell align="center">
                                                          {physician.phone}
                                                      </StyledTableCell>
                                                  
                                                      <LightTooltip  title={
                                                          <React.Fragment>
                                                              {'Address'}
                                                          </React.Fragment>
                                                      }placement="top">
                   
                                                          <StyledTableCell align="center">
                                                              {physician.address}
                                                          </StyledTableCell>
                                                      </LightTooltip>
                                                  
                                                  </StyledTableRow>
                        
                                              );
                                          })
                                          }
                                          <Menu
                                              id="simple-menu"
                                              anchorEl={this.state.anchorElMenu}
                                              keepMounted
                                              open={Boolean(this.state.anchorElMenu)}
                                              onClose={this.handleMenuClose}
                                          >
                                              <MenuItem
                                                  onClick={async () => {
                                                      this.handleMenuClose();
                                                      centralStore.showAddEditDialog();
                                                      centralStore.setPhysicianDataForEdit(
                                                          this.state.currentSelectedPhysician
                                                      );
                                                  }}
                                              >
                        Edit Client Details
                                              </MenuItem>
                                          </Menu>
                                     </TableBody>
                                  </Table>
                              </InfiniteScroll>
                          </TableContainer>
                      </Paper>
                  </Grid>
                  <div className='alert-section'>            
                      {' '}
                      {this.state.openPopup ? (
                          <Snackbar
                              anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center'
                              }}
                 
                              open={true}
                              onClose={this.handlePopupClose}
                          >
                              {this.state.openPopup ? (
                                  <Alert variant="filled"
                                      severity="success" onClose={this.handlePopupClose}>
                                      {this.state.successMessage}
                                  </Alert>
                              ) : null}
                          </Snackbar>
                      ) : null}
                  </div>

              </Grid>
            
          </>
      );
  }
}

export default withRouter(inject('centralStore')(observer(ClientListsDetails)));
