import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { getFormattedDate } from "../../common/util";

class PatientDocInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { centralStore } = this.props;
    const {
      firstName,
      lastName,
      phone,
      email,
      provider,
      dob,
    } = centralStore.selectedPatientDataValue;
    console.log(
      " centralStore.pulseChartDataValue.length===>",
      centralStore.pulseChartDataValue.length
    );
    return (
      <div>
        <Box display="flex" justifyContent="space-between">
          <Box className="info-section">
            <label>Patient Name</label>
            <Typography variant="h5" className="doc-patient-name">
              {`${lastName}, ${firstName}` || "NA"}
            </Typography>

            <Box display="flex" className="contact-info">
              <Box component="p">
                <label>DOB :</label> {dob || ""}
              </Box>
            </Box>

            <Box display="flex" className="contact-info">
              <Box component="p">
                <label>Email :</label>
                <Link to="mailto:name@email.com">{email || ""}</Link>
              </Box>
              <Box component="p">
                <label>Phone :</label> {phone || ""}
              </Box>
            </Box>
          </Box>
          <Box textAlign="right" className="info-section">
            <label>Provider Name</label>
            <Typography variant="h5" className="doc-patient-name">
              Dr. {provider.name} ({provider["custom:qualification"]})
            </Typography>

            <Box display="flex" className="contact-info">
              <Box component="p">
                <label>Email :</label>
                <Link to={"mailto:" + provider.email}>{provider.email}</Link>
              </Box>
              <Box component="p">
                <label>Phone :</label>
                {provider["custom:phone"]
                  ? provider["custom:phone"]
                  : " (Not provided)"}
              </Box>
            </Box>
            <Box component="p">
              <label>Date : </label> {getFormattedDate(new Date(), true)}
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

export default withRouter(inject("centralStore")(observer(PatientDocInfo)));
