import React, { Component } from "react";
import logo from "../../assets/images/MVS-Logo-dark.png";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Alert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errorMessage: null,
      forgetPasswordSuccess: false,
      newPassword: "",
      confirmNewPassword: "",
      code: "",
      successMessage: "",
      passwordResetSuccess: false,
    };
  }
  handleForgotPasswordClick = async () => {
    console.log("email", this.state.email);
    if (this.state.forgetPasswordSuccess) {
      console.log("email In IF");

      if (this.state.newPassword === this.state.confirmNewPassword) {
        this.verifyPasswordCode();
      } else {
        this.setState({ errorMessage: "New Passwords Do not Match" });
      }
    } else {
      if (this.state.email && this.state.email.length > 0) {
        if (!this.state.errorMessage) {
          await Auth.forgotPassword(this.state.email)
            .then((response) => {
              console.log("response", response);
              this.setState({ forgetPasswordSuccess: true });
              this.setState({
                successMessage:
                  "A Code been sent at your given email address Please Confirm It by entering Below...!",
              });
            })
            .catch((error) => {
              console.log(error.message);
              this.setState({ errorMessage: error.message });
              this.setState({ forgetPasswordSuccess: false });
            });
        }
      } else {
        this.setState({ errorMessage: "Email Cannot Be empty" });
      }
    }
  };

  verifyPasswordCode = async () => {
    await Auth.forgotPasswordSubmit(
      this.state.email,
      this.state.code,
      this.state.newPassword
    )
      .then(async (code) => {
        this.setState({
          successMessage: "Password Reset Successfully",
          passwordResetSuccess: true,
          forgetPasswordSuccess: false,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({ errorMessage: error.message });
        this.setState({ errorMessage: error.message });
      });
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: null,
    });
  };

  render() {
    return (
      <div className="login-wrapper">
        <div className="login-box-outer">
          <a href="/" className="login-logo">
            <img src={logo} alt="" className="img-fluid" />
          </a>
          <div className="login-box white-radius-box">
            <h5 className="box-heading text-center">
              Forgot
              <br />
              Password
            </h5>
            {this.state.errorMessage ? (
              <Alert severity="error">{this.state.errorMessage} </Alert>
            ) : null}
            {this.state.forgetPasswordSuccess && !this.state.errorMessage ? (
              <Alert severity="info">{this.state.successMessage}</Alert>
            ) : null}
            {this.state.passwordResetSuccess ? (
              <Alert severity="success">{this.state.successMessage}</Alert>
            ) : null}

            <div className="login-fields">
              <div className="form-field">
                <TextField
                  autoFocus
                  required
                  disabled={this.state.forgetPasswordSuccess}
                  name="email"
                  type="text"
                  label="Enter Your Email"
                  fullWidth={true}
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
              </div>
              {this.state.forgetPasswordSuccess && (
                <this.changePasswordSection />
              )}
            </div>

            {!this.state.passwordResetSuccess ? (
              <div>
                <button
                  onClick={this.handleForgotPasswordClick}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            ) : null}
            <p className="already-member">
              <Link to="/Login">Back</Link>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  changePasswordSection = () => {
    return (
      <>
        <div className="form-field">
          <TextField
            autoFocus
            required
            name="code"
            type="text"
            label="Enter the code"
            fullWidth={true}
            value={this.state.code}
            onChange={this.onInputChange}
          />
        </div>
        <div className="form-field">
          <TextField
            autoFocus
            required
            name="newPassword"
            type="password"
            label="Enter Your New Password"
            fullWidth={true}
            value={this.state.newPassword}
            onChange={this.onInputChange}
          />
        </div>
        <div className="form-field">
          <TextField
            autoFocus
            required
            name="confirmNewPassword"
            type="password"
            label="Confirm New Password"
            fullWidth={true}
            value={this.state.confirmNewPassword}
            onChange={this.onInputChange}
          />
        </div>
      </>
    );
  };
}
