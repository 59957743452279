import * as React from "react";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import { observer, inject } from "mobx-react";
import {
  ArgumentAxis,
  Legend,
  Series,
  Chart,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";

class ActivityChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activityTabValue: 0,
      dataType: "calories",
    };
  }

  activityTabValueChange = (event, newValue) => {
    const { centralStore } = this.props;

    this.setState({ activityTabValue: newValue });
    if (newValue === 0) {
      this.setState({
        dataType: "calories",
        title:
          "Showing " +
          centralStore.activityChartDataValue.length +
          " Activity Calories Readings",
      });
    } else if (newValue === 1) {
      this.setState({
        dataType: "steps",
        title:
          "Showing " +
          centralStore.activityChartDataValue.length +
          " Activity Steps Readings",
      });
    } else {
      this.setState({
        dataType: "distance",
        title:
          "Showing " +
          centralStore.activityChartDataValue.length +
          " Activity Distance Readings",
      });
    }
  };
  customizeTooltip = (pointInfo) => {
    console.log("pointInfo", pointInfo);
    return {
      text: `Date: ${pointInfo.argumentText}<br/>Value: ${pointInfo.valueText}<br/>Time: ${pointInfo.point.data.time.slice(11,16)}`
    };
  };
  render() {
    console.log("this.state.data==>", this.state.data);
    const { centralStore } = this.props;

    return (
      <>
        {centralStore.activityChartDataValue &&
        centralStore.activityChartDataValue.length ? (
          <div>
            <Paper>
              <Tabs
                value={this.state.activityTabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.activityTabValueChange}
                className="chart-tabs"
              >
                <Tab label="Calories" />
                <Tab label="Steps" />
                <Tab label="Distance" />
              </Tabs>
            </Paper>
            <Chart
              adjustOnZoom={true}
              adaptiveLayout={{
                keepLabels: false,
                height: 100,
              }}
              size={{ height: 210 }}
              tooltip={{
                enabled: true,
                customizeTooltip: this.customizeTooltip,
              }}
              dataSource={centralStore.activityChartDataValue}
            >
              <Series
                name={this.state.dataType}
                valueField={this.state.dataType}
                argumentField="timestamp"
                type="bar"
                barWidth={30}
              />

              <ArgumentAxis
                color="#000000"
                width={2}
                title={
                  this.state.title
                    ? this.state.title
                    : "Showing " +
                      centralStore.activityChartDataValue.length +
                      " Activity Calories Readings"
                }
              ></ArgumentAxis>

              <ValueAxis
                width={2}
                color="#000000"
                // title={{ text: "Arterial Pressure", wordWrap: true }}
              ></ValueAxis>
              <Legend
                font={{ size: 16 }}
                visible={true}
                verticalAlignment="bottom"
                horizontalAlignment="center"
                position="outside"
              />
              <Tooltip enabled={true} />
            </Chart>
          </div>
        ) : (
          <h2>No data fetched. Consider Changing filter..!</h2>
        )}
      </>
    );
  }
  componentWillUnmount = () => {
    console.log("unmount");
  };
}
export default inject("centralStore")(observer(ActivityChart));
