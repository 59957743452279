import { getCurrentEnvironment } from "../util";
let backendHost;
// const apiVersion = "v1";
if (getCurrentEnvironment() === "dev") {
  backendHost = "https://api-dev.mvs.services";
} else if (getCurrentEnvironment() === "stage") {
  backendHost = "https://api-stage.mvs.services";
} else if (getCurrentEnvironment() === "prod") {
  backendHost = "https://api.mvs.services";
} else {
  backendHost = "https://api-dev.mvs.services";
}
console.log("backendHost==>", backendHost);
export const baseURL = `${backendHost}`;
