import { get, post, put } from './api';
import { baseURL } from './api-config';
import { StoreInstance } from '../centralstore';

export const addPhysician = async(physiciandetails)=>{
    const body={
        url: `${baseURL}/psu/physicianSignup`,
        data: physiciandetails,
    };
    await post(body).then((response)=>{
        if(response.ok){
            return response.json();
        }else{
            var errorMessage='';
            if(response.status >= 500){
                errorMessage='Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage+':'+response.statusText
                );
            }else{
                errorMessage='Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage+':'+response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const getPhysicianDetails = async () => {
    const body = {
        url: `${baseURL}/arc/physicianlist`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' :'  + response.statusText
                );
            }
        }
    });
};
  
export const updatePhysician = async (physiciandetails)=>{
    const body = {
        url : `${baseURL}/psu/updatePhysician?emailId=${physiciandetails.emailId}`,
        data: physiciandetails,
        headers:{},
    };
    await put(body).then((response)=>{
        if(response.ok){
            return response.json();
        }else{
            var errorMessage = '';
            if(response.status >= 500){
                errorMessage = 'Error From API Side. Report to Back End Developer...';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' +  response.statusText
                );
            }else{
                errorMessage = 'Error From UI Side. Report to Front End Developer...';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' + response.statusText
                );
            }
        }
    });
};

    
// export const deletePhysician = async (physiciandetails) => {
//     const body = {
//         url: `${baseURL}/psu/deletePhysician?emailId=${physiciandetails.emailId}`,
//         data: physiciandetails,
//         headers: {}, 
//     };
//     await deleteapi(body).then((response) => {
//         if (response.ok) {
//             return response.json();
//         } else {
//             var errorMessage = '';
//             if (response.status >= 500) {
//                 errorMessage = 'Error From API Side. Report to Back End Developer...!';
//                 StoreInstance.errorHasOccured(
//                     errorMessage +  ':'  + response.statusText
//                 );
//             } else {
//                 errorMessage = 'Error From UI Side. Report to Front End Developer...!';
//                 StoreInstance.errorHasOccured(
//                     errorMessage + ' :'  + response.statusText
//                 );
//             }
//             throw response.json();
//         }
//     });
// };