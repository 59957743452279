import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { setPatientTableWithFilter, setChartData } from "../common/api-utils";
import { getFormattedDate, getDateDaysBefore } from "../common/util";
import { observer, inject } from "mobx-react";

document.body.classList.remove("bodyStyle");

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateBox: false,
      selectedDateFrom: getFormattedDate(new Date(), true, true),
      selectedDateTo: getFormattedDate(new Date(), true, true),
      dayClickName: "30days",
    };
  }

  handleDateChange = async (dateName, dateValue) => {
    if (dateName === "fromDate") {
      console.log("dateValue", getFormattedDate(dateValue, true, true));

      this.setState({
        selectedDateFrom: getFormattedDate(dateValue, true, true),
      });
    } else if (dateName === "toDate") {
      console.log("dateValue", getFormattedDate(dateValue, true, true));
      this.setState({
        selectedDateTo: getFormattedDate(dateValue, true, true),
      });
    }
  };

  handleCustomDateToggle = async () => {
    this.setState({
      dateBox: !this.state.dateBox,
    });
  };

  handleCustomFilterClick = async () => {
    const { centralStore } = this.props;
    console.log(
      "numberOfVisibleChartsDate",
      centralStore.numberOfVisibleCharts
    );
    if (
      this.state.selectedDateTo === "NaN-NaN-NaN" ||
      this.state.selectedDateFrom === "NaN-NaN-NaN"
    ) {
      alert("Please select a valid date");
    } else {
      if (this.state.selectedDateFrom <= this.state.selectedDateTo) {
        centralStore.setNumberOfVisibleCharts(0);
        this.handleCustomDateToggle();
        let filterValue = {
          startDate: this.state.selectedDateFrom,
          endDate: this.state.selectedDateTo,
        };
        let path = window.location.pathname;
        centralStore.setFilterValue(filterValue);
        if (path === "/home") {
          await setPatientTableWithFilter(filterValue);
        } else if (path === "/patientdashboard") {
          await setChartData(filterValue);
        } else if (path === "/archivedashboard") {
          await setChartData(filterValue);
        }
      } else {
        alert("Start Date should be less than or equal to End Date");
      }
    }
  };

  setDataWithFilter = async (start, end) => {
    const { centralStore } = this.props;
    console.log(
      "numberOfVisibleChartsDate",
      centralStore.numberOfVisibleCharts
    );

    centralStore.setNumberOfVisibleCharts(0);
    let filterValue = {
      startDate: getDateDaysBefore(start),
      endDate: getDateDaysBefore(end),
    };
    let path = window.location.pathname;
    centralStore.setFilterValue(filterValue);
    if (path === "/home") {
      await setPatientTableWithFilter(filterValue);
    } else if (path === "/patientdashboard") {
      await setChartData(filterValue);
    }  else if (path === "/archivedashboard") {
      await setChartData(filterValue);
    }
  };

  render() {
    return (
      <Box className="date-filter">
        <div className="custom-filter">
          <div
            className={
              this.state.dayClickName === "daily" ? "days active" : "days"
            }
            onClick={async () => {
              this.setState({ dayClickName: "daily" });
              this.setDataWithFilter(0, 0);
            }}
          >
            Daily
          </div>
          <div
            className={
              this.state.dayClickName === "7days" ? "days active" : "days"
            }
            onClick={async () => {
              this.setState({ dayClickName: "7days" });

              this.setDataWithFilter(7, 0);
            }}
          >
            7 Days
          </div>
          <div
            className={
              this.state.dayClickName === "30days" ? "days active" : "days"
            }
            onClick={async () => {
              this.setState({ dayClickName: "30days" });

              this.setDataWithFilter(30, 0);
            }}
          >
            30 Days
          </div>
          <div
            className={
              this.state.dayClickName === "customdate" ? "days active" : "days"
            }
            onClick={() => {
              this.setState({ dayClickName: "customdate" });
            }}
          >
            <button
              aria-controls="custom-date"
              aria-haspopup="true"
              className="btn-custom-date"
              onClick={this.handleCustomDateToggle}
            >
              Custom
            </button>
            {this.state.dateBox === true ? (
              <Box className="custom-date-dropdown">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        fullWidth="true"
                        maxDate={getFormattedDate(new Date())}
                        margin="normal"
                        id="date-picker-inline"
                        label="From Date"
                        value={this.state.selectedDateFrom}
                        onChange={(value) => {
                          this.handleDateChange("fromDate", value);
                        }}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        fullWidth="true"
                        maxDate={getFormattedDate(new Date())}
                        margin="normal"
                        id="date-picker-inline"
                        label="To Date"
                        value={this.state.selectedDateTo}
                        onChange={(value) => {
                          this.handleDateChange("toDate", value);
                        }}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <button
                        className="btn btn-primary"
                        onClick={this.handleCustomFilterClick}
                      >
                        GO
                      </button>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
            ) : null}
          </div>
        </div>
      </Box>
    );
  }
}
export default inject("centralStore")(observer(DateFilter));
