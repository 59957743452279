import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import PatientDocInfo from "./PatientDocInfo";
import Box from "@material-ui/core/Box";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Alert from "@material-ui/lab/Alert/Alert";
import { getNotesById } from "../../common/apicall/notes";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BloodPressureChart from "../charts/BloodPressureChart";
import HumanPulseChart from "../charts/HumanPulseChart";
import O2SaturationPulseChart from "../charts/O2SaturationPulseChart";
import ActivityChart from "../charts/ActivityChart";
import GlucoseChart from "../charts/GlucoseChart";
import TemperatureChart from "../charts/TemperatureChart";
import { Container } from "@material-ui/core";
import { Storage } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { sendPdfToPatient } from "../../common/apicall/patient";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Media,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  VerticalAlign,
  HeadingLevel,
  UnderlineType,
  TableLayoutType,
  Header,
  Footer,
  PageNumber,
  PageNumberFormat,
  TableAnchorType,
  RelativeHorizontalPosition,
  WidthType,
  OverlapType,
  RelativeVerticalPosition,
} from "docx";
import { toJS } from "mobx";
import { saveAs } from "file-saver";
import { Snackbar } from "@material-ui/core";
import { imageBase64 } from "../../common/constants";
import "jspdf-autotable";
class ReportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPopup: false,
      sendPdf: false,
      sendEmail: false,
    };
  }

  createDocNotes = (notesData, headerImage) => {
    let notesArr = [];
    const margins = {
      margins: {
        top: 0.5,
        bottom: 0.5,
        left: 0.5,
        right: 0.5,
      },
    };

    const paragraphnotes = new Paragraph({
      children: [
        new TextRun({
          text: "NOTES",
          pageBreakBefore: true,
          bold: true,
          underline: {
            type: UnderlineType.DOUBLE,
            color: "000000",
          },
        }),
      ],
      alignment: AlignmentType.CENTER,
      margins,
      style: "mystyle2",
    });
    const paragraphnotes2 = new Paragraph({
      children: [new TextRun({ text: " " })],
    });

    const docHeaders = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun("Date/ Time")],
                  style: "mystyle2",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun("  Physician Name  ")],
                  style: "mystyle2",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun("  Notes/ SMS/ Emails  ")],
                  style: "mystyle2",
                }),
              ],
            }),
          ],
        }),
      ],
      margins,
    });

    const notesDoc = new Table({
      rows: this.renderRows(this.state.notesData, [
        "timestamp",
        "physicianName",
        "notes",
      ]),
      margins,
    });

    const headerText = "Health Report";

    notesArr.push(
      notesData.addSection({
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                margins,
                style: "mystyle2",
                children: [
                  headerImage,
                  new TextRun({
                    children: [headerText],
                    bold: true,
                    underline: {
                      type: UnderlineType.DOUBLE,
                      color: "000000",
                    },
                  }),
                ],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: ["Page ", PageNumber.CURRENT],
                  }),
                ],
              }),
            ],
          }),
        },
        properties: { pageNumberFormatType: PageNumberFormat.DECIMAL },
        children: [paragraphnotes, paragraphnotes2, docHeaders, notesDoc],
      })
    );
    // //console.log("notesArr", notesArr);
    return notesArr;
  };

  objToString = (o) => {
    Object.keys(o).forEach((k) => {
      if (typeof o[k] === "object") {
        return toString(o[k]);
      }

      o[k] = "" + o[k];
    });

    return o;
  };
  convertAllValuestoString = (array) => {
    return array.map((element) => {
      return this.objToString(element);
    });
  };
  renderArrayoFTables = (doc, headerImage) => {
    const { centralStore } = this.props;
    const {
      activityChartDataValue,
      bpChartDataValue,
      temperatureChartDataValue,
      glucoseChartDataValue,
      o2SaturationChartDataValue,
      pulseChartDataValue,
    } = centralStore;
    let finalTablerray = [];

    const margins = {
      margins: {
        top: 400,
        bottom: 400,
        right: 400,
        left: 400,
      },
    };

    const headerText = "Health Report";

    if (bpChartDataValue && bpChartDataValue.length > 0) {
      //console.log("bpChartDataValue.length", bpChartDataValue.length);
      let bpCount1 = [];
      let bpCount2 = [];
      let bpCount3 = [];
      let bpCount4 = [];
      let bpCount5 = [];
      bpChartDataValue.slice(0, 53).map((item, i) => {
        return bpCount1.push(item);
      });
      //console.log("bpCount1", bpCount1);
      bpChartDataValue.slice(54, 107).map((item, i) => {
        return bpCount2.push(item);
      });
      //console.log("bpCount2", bpCount2);
      bpChartDataValue.slice(108, 161).map((item, i) => {
        return bpCount3.push(item);
      });
      //console.log("bpCount3", bpCount3);
      bpChartDataValue.slice(162, 215).map((item, i) => {
        return bpCount4.push(item);
      });
      //console.log("bpCount4", bpCount4);
      bpChartDataValue.slice(216, 269).map((item, i) => {
        return bpCount5.push(item);
      });
      //console.log("bpCount5", bpCount5);

      const bpChartDataValueheader = new Paragraph({
        children: [
          new TextRun({
            text: "BLOOD PRESSURE",
            bold: true,
            underline: {
              type: UnderlineType.DOUBLE,
              color: "000000",
            },
          }),
        ],
        alignment: AlignmentType.CENTER,
        margins,
        style: "mystyle2",
      });
      const bpChartDataValueheader2 = new Paragraph({
        children: [new TextRun({ text: " " })],
      });

      var bpTable1;
      if (toJS(bpCount1).length !== 0) {
        bpTable1 = new Table({
          rows: this.renderRows(toJS(bpCount1), [
            "time",
            "systolic",
            "diastolic",
            "map",
          ]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var bpTable2;
      if (toJS(bpCount2).length !== 0) {
        bpTable2 = new Table({
          rows: this.renderRows(toJS(bpCount2), [
            "time",
            "systolic",
            "diastolic",
            "map",
          ]),
          // children: [
          //   new Paragraph({
          //     children: [new PageBreak()],
          //   }),
          // ],
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var bpTable3;
      if (toJS(bpCount3).length !== 0) {
        bpTable3 = new Table({
          rows: this.renderRows(toJS(bpCount3), [
            "time",
            "systolic",
            "diastolic",
            "map",
          ]),
          children: [
            new Paragraph({
              pageBreakBefore: true,
            }),
          ],
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var bpTable4;
      if (toJS(bpCount4).length !== 0) {
        bpTable4 = new Table({
          rows: this.renderRows(toJS(bpCount4), [
            "time",
            "systolic",
            "diastolic",
            "map",
          ]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var bpTable5;
      if (toJS(bpCount5).length !== 0) {
        bpTable5 = new Table({
          rows: this.renderRows(toJS(bpCount5), [
            "time",
            "systolic",
            "diastolic",
            "map",
          ]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      finalTablerray.push(
        new Paragraph({
          alignment: AlignmentType.CENTER,
          margins,
          style: "mystyle2",
          children: [
            new TextRun({
              children: [headerText],
              bold: true,
              underline: {
                type: UnderlineType.DOUBLE,
                color: "000000",
              },
            }),
          ],
        }),
        doc.addSection({
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  margins,
                  style: "mystyle2",
                  children: [
                    headerImage,
                    new TextRun({
                      children: [headerText],
                      bold: true,
                      underline: {
                        type: UnderlineType.DOUBLE,
                        color: "000000",
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Page ", PageNumber.CURRENT],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            pageNumberFormatType: PageNumberFormat.DECIMAL,
          },
          children: [
            bpChartDataValueheader,
            bpChartDataValueheader2,
            bpTable1,
            bpTable2,
            bpTable3,
            bpTable4,
            bpTable5,
          ],
        })
      );
    }
    // finalTablerray.push(bpChartDataValueheader, table1)

    if (
      pulseChartDataValue.arrayData &&
      pulseChartDataValue.arrayData.length > 0
    ) {
      let pulseCount1 = [];
      let pulseCount2 = [];
      let pulseCount3 = [];
      let pulseCount4 = [];
      let pulseCount5 = [];
      pulseChartDataValue.arrayData.slice(0, 53).map((item, i) => {
        return pulseCount1.push(item);
      });
      //console.log("pulseCount1", pulseCount1);
      pulseChartDataValue.arrayData.slice(54, 107).map((item, i) => {
        return pulseCount2.push(item);
      });
      //console.log("pulseCount2", pulseCount2);
      pulseChartDataValue.arrayData.slice(108, 161).map((item, i) => {
        return pulseCount3.push(item);
      });
      //console.log("pulseCount3", pulseCount3);
      pulseChartDataValue.arrayData.slice(162, 215).map((item, i) => {
        return pulseCount4.push(item);
      });
      //console.log("pulseCount4", pulseCount4);
      pulseChartDataValue.arrayData.slice(216, 269).map((item, i) => {
        return pulseCount5.push(item);
      });
      //console.log("pulseCount5", pulseCount5);
      var pulseTable1;
      if (toJS(pulseCount1).length !== 0) {
        pulseTable1 = new Table({
          rows: this.renderRows(toJS(pulseCount1), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }
      var pulseTable2;
      if (toJS(pulseCount2).length !== 0) {
        pulseTable2 = new Table({
          rows: this.renderRows(toJS(pulseCount2), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }
      var pulseTable3;
      if (toJS(pulseCount3).length !== 0) {
        pulseTable3 = new Table({
          rows: this.renderRows(toJS(pulseCount3), ["time", "value"]),
          children: [
            new Paragraph({
              pageBreakBefore: true,
            }),
          ],
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var pulseTable4;
      if (toJS(pulseCount4).length !== 0) {
        pulseTable4 = new Table({
          rows: this.renderRows(toJS(pulseCount4), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var pulseTable5;
      if (toJS(pulseCount5).length !== 0) {
        pulseTable5 = new Table({
          rows: this.renderRows(toJS(pulseCount5), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      const pulseHeader = new Paragraph({
        children: [
          new TextRun({
            text: "PULSE RATE",
            pageBreakBefore: true,
            bold: true,
            underline: {
              type: UnderlineType.DOUBLE,
              color: "000000",
            },
          }),
        ],
        alignment: AlignmentType.CENTER,
        margins,
        style: "mystyle2",
      });
      const pulseHeader2 = new Paragraph({
        children: [new TextRun({ text: " " })],
      });
      // finalTablerray.push(pulseHeader, table5);

      finalTablerray.push(
        doc.addSection({
          children: [
            pulseHeader,
            pulseHeader2,
            pulseTable1,
            pulseTable2,
            pulseTable3,
            pulseTable4,
            pulseTable5,
          ],

          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  margins,
                  style: "mystyle2",
                  children: [
                    headerImage,
                    new TextRun({
                      children: [headerText],
                      bold: true,
                      underline: {
                        type: UnderlineType.DOUBLE,
                        color: "000000",
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Page ", PageNumber.CURRENT],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            pageNumberFormatType: PageNumberFormat.DECIMAL,
          },
        })
      );
    }

    if (temperatureChartDataValue && temperatureChartDataValue.length > 0) {
      let tempCount1 = [];
      let tempCount2 = [];
      let tempCount3 = [];
      let tempCount4 = [];
      let tempCount5 = [];
      temperatureChartDataValue.slice(0, 53).map((item, i) => {
        return tempCount1.push(item);
      });
      //console.log("o2Count1", o2Count1);
      temperatureChartDataValue.slice(54, 107).map((item, i) => {
        return tempCount2.push(item);
      });
      //console.log("o2Count2", o2Count2);
      temperatureChartDataValue.slice(108, 161).map((item, i) => {
        return tempCount3.push(item);
      });
      //console.log("o2Count3", o2Count3);
      temperatureChartDataValue.slice(162, 215).map((item, i) => {
        return tempCount4.push(item);
      });
      //console.log("o2Count4", o2Count4);
      temperatureChartDataValue.slice(216, 269).map((item, i) => {
        return tempCount5.push(item);
      });
      //console.log("o2Count5", o2Count5);

      const temperatureHeader = new Paragraph({
        children: [
          new TextRun({
            text: "TEMPERATURE",
            pageBreakBefore: true,
            bold: true,
            underline: {
              type: UnderlineType.DOUBLE,
              color: "000000",
            },
          }),
        ],
        alignment: AlignmentType.CENTER,
        margins,
        style: "mystyle2",
      });
      const temperatureHeader2 = new Paragraph({
        children: [new TextRun({ text: " " })],
      });

      var tempTable1;
      if (toJS(tempCount1).length !== 0) {
        tempTable1 = new Table({
          rows: this.renderRows(toJS(tempCount1), ["time", "valueF", "valueC"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var tempTable2;
      if (toJS(tempCount2).length !== 0) {
        tempTable2 = new Table({
          rows: this.renderRows(toJS(tempCount2), ["time", "valueF", "valueC"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var tempTable3;
      if (toJS(tempCount3).length !== 0) {
        tempTable3 = new Table({
          rows: this.renderRows(toJS(tempCount3), ["time", "valueF", "valueC"]),
          children: [
            new Paragraph({
              pageBreakBefore: true,
            }),
          ],
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var tempTable4;
      if (toJS(tempCount4).length !== 0) {
        tempTable4 = new Table({
          rows: this.renderRows(toJS(tempCount4), ["time", "valueF", "valueC"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var tempTable5;
      if (toJS(tempCount5).length !== 0) {
        tempTable5 = new Table({
          rows: this.renderRows(toJS(tempCount5), ["time", "valueF", "valueC"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      // finalTablerray.push(temperatureHeader, table2);

      finalTablerray.push(
        doc.addSection({
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  margins,
                  style: "mystyle2",
                  children: [
                    headerImage,
                    new TextRun({
                      children: [headerText],
                      bold: true,
                      underline: {
                        type: UnderlineType.DOUBLE,
                        color: "000000",
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Page ", PageNumber.CURRENT],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            pageNumberFormatType: PageNumberFormat.DECIMAL,
          },
          children: [
            temperatureHeader,
            temperatureHeader2,
            tempTable1,
            tempTable2,
            tempTable3,
            tempTable4,
            tempTable5,
          ],
        })
      );
    }

    if (glucoseChartDataValue && glucoseChartDataValue.length > 0) {
      let glucoseCount1 = [];
      let glucoseCount2 = [];
      let glucoseCount3 = [];
      let glucoseCount4 = [];
      let glucoseCount5 = [];
      glucoseChartDataValue.slice(0, 53).map((item, i) => {
        return glucoseCount1.push(item);
      });
      glucoseChartDataValue.slice(54, 107).map((item, i) => {
        return glucoseCount2.push(item);
      });
      glucoseChartDataValue.slice(108, 161).map((item, i) => {
        return glucoseCount3.push(item);
      });
      glucoseChartDataValue.slice(162, 215).map((item, i) => {
        return glucoseCount4.push(item);
      });
      glucoseChartDataValue.slice(216, 269).map((item, i) => {
        return glucoseCount5.push(item);
      });
      const glucoseHeader = new Paragraph({
        children: [
          new TextRun({
            text: "GLUCOSE",
            pageBreakBefore: true,
            bold: true,
            underline: {
              type: UnderlineType.DOUBLE,
              color: "000000",
            },
          }),
        ],
        alignment: AlignmentType.CENTER,
        margins,
        style: "mystyle2",
      });
      const glucoseHeader2 = new Paragraph({
        children: [new TextRun({ text: " " })],
      });

      var glucoseTable1;
      if (toJS(glucoseCount1).length !== 0) {
        glucoseTable1 = new Table({
          rows: this.renderRows(toJS(glucoseCount1), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var glucoseTable2;
      if (toJS(glucoseCount2).length !== 0) {
        glucoseTable2 = new Table({
          rows: this.renderRows(toJS(glucoseCount2), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var glucoseTable3;
      if (toJS(glucoseCount3).length !== 0) {
        glucoseTable3 = new Table({
          rows: this.renderRows(toJS(glucoseCount3), ["time", "value"]),
          children: [
            new Paragraph({
              pageBreakBefore: true,
            }),
          ],
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var glucoseTable4;
      if (toJS(glucoseCount4).length !== 0) {
        glucoseTable4 = new Table({
          rows: this.renderRows(toJS(glucoseCount4), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var glucoseTable5;
      if (toJS(glucoseCount5).length !== 0) {
        glucoseTable5 = new Table({
          rows: this.renderRows(toJS(glucoseCount5), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      // finalTablerray.push(glucoseHeader, table3);

      finalTablerray.push(
        doc.addSection({
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  margins,
                  style: "mystyle2",
                  children: [
                    headerImage,
                    new TextRun({
                      children: [headerText],
                      bold: true,
                      underline: {
                        type: UnderlineType.DOUBLE,
                        color: "000000",
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Page ", PageNumber.CURRENT],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            pageNumberFormatType: PageNumberFormat.DECIMAL,
          },
          children: [
            glucoseHeader,
            glucoseHeader2,
            glucoseTable1,
            glucoseTable2,
            glucoseTable3,
            glucoseTable4,
            glucoseTable5,
          ],
        })
      );
    }

    if (o2SaturationChartDataValue && o2SaturationChartDataValue.length > 0) {
      let o2Count1 = [];
      let o2Count2 = [];
      let o2Count3 = [];
      let o2Count4 = [];
      let o2Count5 = [];
      o2SaturationChartDataValue.slice(0, 53).map((item, i) => {
        return o2Count1.push(item);
      });
      //console.log("o2Count1", o2Count1);
      o2SaturationChartDataValue.slice(54, 107).map((item, i) => {
        return o2Count2.push(item);
      });
      //console.log("o2Count2", o2Count2);
      o2SaturationChartDataValue.slice(108, 161).map((item, i) => {
        return o2Count3.push(item);
      });
      //console.log("o2Count3", o2Count3);
      o2SaturationChartDataValue.slice(162, 215).map((item, i) => {
        return o2Count4.push(item);
      });
      //console.log("o2Count4", o2Count4);
      o2SaturationChartDataValue.slice(216, 269).map((item, i) => {
        return o2Count5.push(item);
      });
      //console.log("o2Count5", o2Count5);
      var o2Table1;
      if (toJS(o2Count1).length !== 0) {
        o2Table1 = new Table({
          rows: this.renderRows(toJS(o2Count1), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var o2Table2;
      if (toJS(o2Count2).length !== 0) {
        o2Table2 = new Table({
          rows: this.renderRows(toJS(o2Count2), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var o2Table3;
      if (toJS(o2Count3).length !== 0) {
        o2Table3 = new Table({
          rows: this.renderRows(toJS(o2Count3), ["time", "value"]),
          children: [
            new Paragraph({
              pageBreakBefore: true,
            }),
          ],
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var o2Table4;
      if (toJS(o2Count4).length !== 0) {
        o2Table4 = new Table({
          rows: this.renderRows(toJS(o2Count4), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var o2Table5;
      if (toJS(o2Count5).length !== 0) {
        o2Table5 = new Table({
          rows: this.renderRows(toJS(o2Count5), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      const O2Header = new Paragraph({
        children: [
          new TextRun({
            text: "O2 SATURATION",
            pageBreakBefore: true,
            bold: true,
            underline: {
              type: UnderlineType.DOUBLE,
              color: "000000",
            },
          }),
        ],
        alignment: AlignmentType.CENTER,
        margins,
        style: "mystyle2",
      });
      const O2Header2 = new Paragraph({
        children: [new TextRun({ text: " " })],
      });
      // finalTablerray.push(O2Header, table4);

      finalTablerray.push(
        doc.addSection({
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  margins,
                  style: "mystyle2",
                  children: [
                    headerImage,
                    new TextRun({
                      children: [headerText],
                      bold: true,
                      underline: {
                        type: UnderlineType.DOUBLE,
                        color: "000000",
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Page ", PageNumber.CURRENT],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            pageNumberFormatType: PageNumberFormat.DECIMAL,
          },
          children: [
            O2Header,
            O2Header2,
            o2Table1,
            o2Table2,
            o2Table3,
            o2Table4,
            o2Table5,
          ],
        })
      );
    }
    // finalTablerray.push(pulseHeader, table5);

    if (activityChartDataValue && activityChartDataValue.length > 0) {
      let activityCount1 = [];
      let activityCount2 = [];
      let activityCount3 = [];
      let activityCount4 = [];
      let activityCount5 = [];
      activityChartDataValue.slice(0, 53).map((item, i) => {
        return activityCount1.push(item);
      });
      //console.log("o2Count1", o2Count1);
      activityChartDataValue.slice(54, 107).map((item, i) => {
        return activityCount2.push(item);
      });
      //console.log("o2Count2", o2Count2);
      activityChartDataValue.slice(108, 161).map((item, i) => {
        return activityCount3.push(item);
      });
      //console.log("o2Count3", o2Count3);
      activityChartDataValue.slice(162, 215).map((item, i) => {
        return activityCount4.push(item);
      });
      //console.log("o2Count4", o2Count4);
      activityChartDataValue.slice(216, 269).map((item, i) => {
        return activityCount5.push(item);
      });
      //console.log("o2Count5", o2Count5);
      const activityHeader = new Paragraph({
        children: [
          new TextRun({
            text: "ACTIVITY",
            pageBreakBefore: true,
            bold: true,
            underline: {
              type: UnderlineType.DOUBLE,
              color: "000000",
            },
          }),
        ],
        alignment: AlignmentType.CENTER,
        margins,
        style: "mystyle2",
      });
      const activityHeader2 = new Paragraph({
        children: [new TextRun({ text: " " })],
      });

      var activityTable1;
      if (toJS(activityCount1).length !== 0) {
        activityTable1 = new Table({
          rows: this.renderRows(toJS(activityCount1), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var activityTable2;
      if (toJS(activityCount2).length !== 0) {
        activityTable2 = new Table({
          rows: this.renderRows(toJS(activityCount2), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var activityTable3;
      if (toJS(activityCount3).length !== 0) {
        activityTable3 = new Table({
          rows: this.renderRows(toJS(activityCount3), ["time", "value"]),
          children: [
            new Paragraph({
              pageBreakBefore: true,
            }),
          ],
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var activityTable4;
      if (toJS(activityCount4).length !== 0) {
        activityTable4 = new Table({
          rows: this.renderRows(toJS(activityCount4), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }

      var activityTable5;
      if (toJS(activityCount5).length !== 0) {
        activityTable5 = new Table({
          rows: this.renderRows(toJS(activityCount5), ["time", "value"]),
          float: {
            horizontalAnchor: TableAnchorType.TEXT,
            verticalAnchor: TableAnchorType.TEXT,
            relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
            relativeVerticalPosition: RelativeVerticalPosition.TOP,
            overlap: OverlapType.NEVER,
          },
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.AUTOFIT,
        });
      }
      //finalTablerray.push(activityHeader, table6);

      finalTablerray.push(
        doc.addSection({
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  margins,
                  style: "mystyle2",
                  children: [
                    headerImage,
                    new TextRun({
                      children: [headerText],
                      bold: true,
                      underline: {
                        type: UnderlineType.DOUBLE,
                        color: "000000",
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Page ", PageNumber.CURRENT],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            pageNumberFormatType: PageNumberFormat.DECIMAL,
          },
          children: [
            activityHeader,
            activityHeader2,
            activityTable1,
            activityTable2,
            activityTable3,
            activityTable4,
            activityTable5,
          ],
        })
      );
    }
    // //console.log("finalTablerray", finalTablerray);
    return finalTablerray;
  };

  renderRows = (arrayData, arrayOfCloumns) => {
    let rowForColumns = [];
    arrayOfCloumns.map((column) => {
      return rowForColumns.push(
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun(String(column))],
              style: "mystyle",
            }),
          ],
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
            right: 50,
          },
          alignment: AlignmentType.CENTER,
          verticalAlign: VerticalAlign.CENTER,
        })
      );
    });

    var dataRows = arrayData.map((data) => {
      // //console.log("dataRows", data);
      return new TableRow({
        children: arrayOfCloumns.map((column) => {
          // //console.log("arrayOfCloumns", column, " : ", String(data[column]));
          return new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun(String(data[column]))],
                style: "mystyle",
              }),
            ],
            margins: {
              top: 50,
              bottom: 50,
              left: 50,
              right: 50,
            },
            verticalAlign: VerticalAlign.CENTER,
          });
        }),
        margins: {
          top: 50,
          bottom: 50,
          left: 50,
          right: 50,
        },
      });
    });

    var finalColumns = [
      new TableRow({
        children: rowForColumns,
        margins: {
          top: 50,
          bottom: 50,
          left: 50,
          right: 50,
        },
      }),
    ];
    //console.log("finalColumnsbef", finalColumns);
    //console.log("rowForColumns", rowForColumns);
    return finalColumns.concat(dataRows);
  };

  drawPdfTables = (pdf) => {
    const { centralStore } = this.props;
    const {
      activityChartDataValue,
      bpChartDataValue,
      temperatureChartDataValue,
      glucoseChartDataValue,
      o2SaturationChartDataValue,
      pulseChartDataValue,
    } = centralStore;

    let arrayOfChartData = [
      {
        tableName: "Blood Pressure",
        arryKeys: ["time", "map", "systolic", "diastolic"],
        arrayColumn: [
          "Date / Time",
          "MAP Values",
          "Systolic Values",
          "Diastolic Values",
        ],
        arrayData: bpChartDataValue,
      },
      {
        tableName: "Activity",
        arryKeys: ["time", "value"],
        arrayColumn: ["Date / Time", "Activity"],
        arrayData: activityChartDataValue,
      },
      {
        tableName: "Temperature",
        arryKeys: ["time", "value"],
        arrayColumn: ["Date / Time", "Temperature"],
        arrayData: temperatureChartDataValue,
      },
      {
        tableName: "Glucose",
        arryKeys: ["time", "value"],
        arrayColumn: ["Date / Time", "Glucose"],
        arrayData: glucoseChartDataValue,
      },
      {
        tableName: "O2 Saturation",
        arryKeys: ["time", "value"],
        arrayColumn: ["Date / Time", "O2 Saturation"],
        arrayData: o2SaturationChartDataValue,
      },
      {
        tableName: "Pulse Rate",
        arryKeys: ["time", "value"],
        arrayColumn: ["Date / Time", "Pulse Rate"],
        arrayData: pulseChartDataValue.arrayData,
      },
    ];
    return arrayOfChartData.forEach((chartData) => {
      if (chartData.arrayData && chartData.arrayData.length) {
        pdf.addPage();
        pdf.line(20, 25, 260, 25); // header line
        pdf.line(20, 190, 260, 190); //footer line
        pdf.text(chartData.tableName, 90, 40);
        pdf.line(90, 42, 115, 42); // horizontal line
        pdf.line(90, 43, 115, 43); // horizontal line
        let bodyOfTable = [];
        chartData.arrayData.forEach((data) => {
          let arr = chartData.arryKeys.map((key) => {
            //console.log("=============KEY=============", key);
            return data[key];
          });
          //console.log("=============JSDOC=============", arr);
          return bodyOfTable.push(arr);
        });
        return pdf.autoTable({
          startY: 50,
          margin: {
            top: 50,
            left: 90,
          },
          didDrawPage: function(data) {
            // Header
            pdf.setFontSize(20);
            pdf.setTextColor(40);
            if (imageBase64) {
              pdf.addImage(imageBase64, "PNG", 200, 10, 20, 10);
            }
            pdf.text("Health Report", 223, 20);

            // Footer
            var str = "Page " + pdf.internal.getNumberOfPages();
            // Total page number plugin only available in jspdf v1.0+

            pdf.setFontSize(10);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = pdf.internal.pageSize;
            var pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
            pdf.text(str, data.settings.margin.left + 150, pageHeight - 10);
          },
          // rowPageBreak: 'auto',
          tableWidth: "wrap",
          // columnWidth: 'auto',
          // overFlow: 'linebreak',
          head: [chartData.arrayColumn],
          body: bodyOfTable,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 1,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
          },
          bodyStyles: {
            fillColor: [255, 255, 255],
          },
        });
      }
    });
  };

  printDocument = async () => {
    const { centralStore } = this.props;
    const { firstName, lastName } = centralStore.selectedPatientDataValue;

    let patient_id = localStorage.getItem("patient_id");
    await getNotesById(patient_id).then((response) => {
      //console.log("response Notes===>", response);
      this.setState({ notesData: response });
    });

    let chartImage1 = null;
    let chartImage2 = null;
    let name = firstName + ", " + lastName;

    if (centralStore.numberOfVisibleCharts > 4) {
      chartImage1 = document.getElementById("chartImg1");
      chartImage2 = document.getElementById("chartImg2");
      //console.log("ChartImage1===>", chartImage1);
      //console.log("ChartImage2===>", chartImage2);
      await html2canvas(chartImage1).then(async (canvas1) => {
        await html2canvas(chartImage2).then(async (canvas2) => {
          const imgData1 = canvas1.toDataURL("image/png");
          const imgData2 = canvas2.toDataURL("image/png");
          //console.log("ChartImage2===>");
          const pdf = new jsPDF({
            orientation: "portrait",
            precision: 10,
          });
          pdf.addImage(imageBase64, "PNG", 100, 1, 20, 8);
          pdf
            .setFont("Helvetica", "bold")
            .text("Health Report", 122, 7)
            .setFontSize(10);
          pdf.setFont("Helvetica", "bold");
          pdf.addImage(imgData1, "PNG", 20, 10);
          pdf.line(20, 190, 260, 190); //footerline
          pdf.addPage();
          pdf.addImage(imgData2, "PNG", 20, 10);
          pdf.line(20, 190, 260, 190); //footerline
          pdf.addPage();
          this.drawPdfTables(pdf);
          pdf.addPage();
          this.addHeaderAndFooter(pdf);
          pdf.setFontSize(10);
          pdf.text("Notes", 30, 40);
          pdf.line(30, 42, 45, 42); // horizontal line
          pdf.line(30, 43, 45, 43); // horizontal line
          let bodyforNotes = [];
          if (this.state.notesData) {
            this.state.notesData.forEach((data) => {
              let arr = ["date", "physicianName", "notes"].map((key) => {
                //console.log("=============KEY=============", key);
                return data[key];
              });
              //console.log("=============JSDOC=============", arr);
              // var splitTitle = pdf.splitTextToSize(arr, 200);
              // //console.log("splitTitle", splitTitle);
              // doc.text(15, 20, splitTitle);

              bodyforNotes.push(arr);
            });
          }
          pdf.autoTable({
            startY: 50,
            margin: {
              top: 50,
              left: 30,
            },
            rowPageBreak: "auto",
            // columnWidth: 'auto',
            overFlow: "linebreak",

            head: [["Date/ Time", "Physician Name", "Notes/ SMS/ Emails"]],
            body: bodyforNotes,
            // tableWidth: '100',
            tableLineColor: [0, 0, 0],
            tableLineWidth: 1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              valign: "top",
            },
          });

          pdf.save("MVS_PDF_Report_" + name);
          window.open(pdf.output("blob"));
          this.uploadPdfFileToS3(pdf.output("blob"));
        });
        this.handleReportDialogClose();
      });
    } else {
      const input = document.getElementById("divToPrint");
      await html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "landscape",
          precision: 10,
        });
        pdf.addImage(imageBase64, "PNG", 100, 1, 20, 8);
        pdf
          .setFont("Helvetica", "bold")
          .text("Health Report", 122, 7)
          .setFontSize(10);
        pdf.setFont("Helvetica", "bold");
        pdf.addImage(imgData, "PNG", 20, 10);
        pdf.line(20, 190, 260, 190); //footerline
        this.drawPdfTables(pdf);
        pdf.addPage();
        this.addHeaderAndFooter(pdf);
        pdf.setFontSize(10);
        pdf.text("Notes", 30, 40);
        pdf.line(30, 42, 45, 42); // horizontal line
        pdf.line(30, 43, 45, 43); // horizontal line
        let bodyforNotes = [];
        if (this.state.notesData.length) {
          this.state.notesData.forEach((data) => {
            let arr = ["date", "physicianName", "notes"].map((key) => {
              //console.log("=============KEY=============", key);
              return data[key];
            });
            //console.log("=============JSDOC=============", arr);
            bodyforNotes.push(arr);
          });
        }
        pdf.autoTable({
          startY: 50,
          margin: {
            top: 50,
            left: 30,
          },
          rowPageBreak: "auto",
          // columnWidth: 'auto',
          overFlow: "linebreak",

          head: [["Date/ Time", "Physician Name", "Notes/ SMS/ Emails"]],
          body: bodyforNotes,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 1,
          // tableWidth: '100',
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
          },
          bodyStyles: {
            fillColor: [255, 255, 255],
            valign: "top",
          },
        });

        pdf.save("MVS_PDF_Report_" + name);
        window.open(pdf.output("blob"));
        this.uploadPdfFileToS3(pdf.output("blob"));
        this.handleReportDialogClose();
      });
    }
  };

  addHeaderAndFooter = (pdf) => {
    pdf.text("Page " + pdf.internal.getNumberOfPages(), 240, 200); //footer Text
    pdf.line(20, 190, 260, 190); //footerline
    pdf.setFontSize(20);
    pdf.setTextColor(40);
    // pdf.addImage(imageBase64, "PNG", 200, 10, 30, 10); //header Image
    pdf.addImage(imageBase64, "PNG", 30, 10, 20, 10);
    pdf.text("Health Report", 50, 20);
    // pdf.text("Health Report", 120, 20); // Header Text
    pdf.line(20, 30, 260, 30); //header line
  };
  printDocFile = async () => {
    const { centralStore } = this.props;
    const { firstName, lastName } = centralStore.selectedPatientDataValue;
    let patient_id = localStorage.getItem("patient_id");
    await getNotesById(patient_id).then((response) => {
      //console.log("response Notes===>", response);
      this.setState({ notesData: response });
    });

    let chartImage1 = null;
    let chartImage2 = null;
    let name = firstName + ", " + lastName;

    if (centralStore.numberOfVisibleCharts > 4) {
      chartImage1 = document.getElementById("chartImg1");
      chartImage2 = document.getElementById("chartImg2");

      await html2canvas(chartImage1).then(async (canvas1) => {
        await html2canvas(chartImage2).then(async (canvas2) => {
          const imgData1 = canvas1.toDataURL("image/png");
          const imgData2 = canvas2.toDataURL("image/png");

          const doc = new Document({
            styles: {
              paragraphStyles: [
                {
                  id: "mystyle",
                  name: "mystyle",
                  run: {
                    size: 12,
                    font: "Arial",
                  },
                },
                {
                  id: "mystyle2",
                  name: "mystyle2",
                  run: {
                    size: 16,
                    font: "Arial",
                    heading: HeadingLevel.HEADING_2,
                    alignment: AlignmentType.CENTER,
                  },
                },
              ],
            },
          });
          let headerImage = Media.addImage(
            doc,
            Uint8Array.from(atob(imageBase64.split(",")[1]), (c) =>
              c.charCodeAt(0)
            ),
            100,
            50
          );
          const image1 = Media.addImage(
            doc,
            Uint8Array.from(
              atob(imgData1.split(",")[1], imgData2.split(",")[1]),
              (c) => c.charCodeAt(0)
            ),
            600,
            400
          );

          const headerText = "Health Report";
          const margins = {
            margins: {
              top: 400,
              bottom: 400,
              right: 400,
              left: 400,
            },
          };

          let childrenArray = [new Paragraph(image1)];
          doc.addSection({
            headers: {
              default: new Header({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    margins,
                    style: "mystyle2",
                    children: [
                      headerImage,
                      new TextRun({
                        children: [headerText],
                        bold: true,
                        underline: {
                          type: UnderlineType.DOUBLE,
                          color: "000000",
                        },
                      }),
                    ],
                  }),
                ],
              }),
            },
            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [
                      new TextRun({
                        children: ["Page ", PageNumber.CURRENT],
                      }),
                    ],
                  }),
                ],
              }),
            },
            properties: {
              pageNumberFormatType: PageNumberFormat.DECIMAL,
            },
            children: childrenArray,
          });

          this.renderArrayoFTables(doc, headerImage);

          this.createDocNotes(doc, headerImage);

          Packer.toBlob(doc).then((blob) => {
            //console.log(blob);
            saveAs(blob, "MVS_Doc_Report_" + name);
            //console.log("Document created successfully");
          });
        });
        this.handleReportDialogClose();
      });
    } else {
      const input = document.getElementById("divToPrint");

      await html2canvas(input).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const doc = new Document({
          styles: {
            paragraphStyles: [
              {
                id: "mystyle",
                name: "mystyle",
                run: {
                  size: 12,
                  font: "Arial",
                },
              },
              {
                id: "mystyle2",
                name: "mystyle2",
                run: {
                  size: 16,
                  font: "Arial",
                  heading: HeadingLevel.HEADING_2,
                  alignment: AlignmentType.CENTER,
                },
              },
            ],
          },
        });

        let headerImage = Media.addImage(
          doc,
          Uint8Array.from(atob(imageBase64.split(",")[1]), (c) =>
            c.charCodeAt(0)
          ),
          70,
          30
        );
        const image1 = Media.addImage(
          doc,
          Uint8Array.from(atob(imgData.split(",")[1]), (c) => c.charCodeAt(0)),
          600,
          400
        );

        const headerText = "Health Report";
        const margins = {
          margins: {
            top: 400,
            bottom: 400,
            right: 400,
            left: 400,
          },
        };
        let childrenArray = [new Paragraph(image1)];
        //console.log("childrenArray", childrenArray);

        doc.addSection({
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  margins,
                  style: "mystyle2",
                  children: [
                    headerImage,
                    new TextRun({
                      children: [headerText],
                      bold: true,
                      underline: {
                        type: UnderlineType.DOUBLE,
                        color: "000000",
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Page ", PageNumber.CURRENT],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            pageNumberFormatType: PageNumberFormat.DECIMAL,
          },
          children: childrenArray,
        });

        this.renderArrayoFTables(doc, headerImage);

        this.createDocNotes(doc, headerImage);

        Packer.toBlob(doc).then((blob) => {
          //console.log(blob);
          saveAs(blob, "MVS_Doc_Report_" + name);
          //console.log("Document created successfully");
        });

        this.handleReportDialogClose();
      });
    }
  };

  uploadPdfFileToS3 = (doc) => {
    const { centralStore } = this.props;
    let patient_id = localStorage.getItem("patient_id");
    const { firstName, lastName } = centralStore.selectedPatientDataValue;
    let name = firstName + ", " + lastName;
    let input = patient_id + ", " + name;

    Storage.put(`mvs-webapp-dev/mvs-provider-report/${input}.pdf`, doc)
      .then(async (result) => {
        //console.log("s3upload===>", result);
        // this.setState({ uploadComplete: true });
      })
      .catch((err) => {
        console.log("s3uploadERROR==>", err);
      });
  };

  createHeaders = (keys, headColumnName) => {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: headColumnName[i],
        width: 65,
        align: "center",
        padding: 0,
      });
    }
    return result;
  };

  handleReportDialogClose = () => {
    const { centralStore } = this.props;
    centralStore.hideReportDialog();
  };

  handleClickOpen = () => {
    this.setState({ sendPdf: true });
    this.setState({ openPopup: true });
  };

  handleClose = () => {
    this.setState({ openPopup: false });
  };

  sendEmailFlag = () => {
    this.setState({ sendEmail: true });
    this.printDocument();
  };

  sendPdf = () => {
    this.printDocument();
    let patient_id = localStorage.getItem("patient_id");
    const { centralStore } = this.props;
    const { firstName, lastName } = centralStore.selectedPatientDataValue;
    let name = firstName + ", " + lastName;
    let input = patient_id + ", " + firstName + ", " + lastName + ".pdf";
    let dataBody = {};
    dataBody = {
      recipientEmail: this.state.emailIDs,
      fileName: input,
      subject: "MVS Report of " + name,
      bodytext: "Health Report 0f " + name,
      bodyHtml:
        "Please find the attached Health report of " + name + ", Thanks MVS.",
    };
    //console.log("dataBody", dataBody);
    sendPdfToPatient(dataBody).then((response) => {
      const { centralStore } = this.props;
      centralStore.showLoader();
      //console.log("response for Message==>", response);
      this.setState({ successMessage: "Messsage sent successfully" });
      centralStore.hideLoader();
      //console.log("this.state.successMessage", this.state.successMessage);
    });

    this.setState({ openPopup: false });
  };

  oneImageComponent = () => {
    const { centralStore } = this.props;
    const { humanId } = centralStore.selectedPatientDataValue;

    return (
      <div id="divToPrint">
        <div className="content">
          <Box
            display="flex"
            justifyContent="space-between"
            className="page-header"
          ></Box>
          <Box className="patient-and-doc-info">
            <PatientDocInfo />
          </Box>
          <Grid container spacing={3} className="patient-detail-charts">
            <Grid
              style={
                !centralStore.bpChartDataValue ||
                !centralStore.bpChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Blood pressure / Mean arterial pressure
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <BloodPressureChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.pulseChartDataValue.data ||
                !centralStore.pulseChartDataValue.data.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Pulse
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <HumanPulseChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.o2SaturationChartDataValue ||
                !centralStore.o2SaturationChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  O2 Saturation/Pulse
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <O2SaturationPulseChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.temperatureChartDataValue ||
                !centralStore.temperatureChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Temperature
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <TemperatureChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="patient-detail-charts">
            <Grid
              style={
                !centralStore.glucoseChartDataValue ||
                !centralStore.glucoseChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Glucose
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <GlucoseChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}{" "}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.activityChartDataValue ||
                !centralStore.activityChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Activity
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <ActivityChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  twoImagesComponent = () => {
    const { centralStore } = this.props;
    const { humanId } = centralStore.selectedPatientDataValue;

    return (
      <>
        <div id="chartImg1">
          <Box
            display="flex"
            justifyContent="space-between"
            className="page-header"
          ></Box>
          <Box className="patient-and-doc-info">
            <PatientDocInfo />
          </Box>
          <Grid container spacing={3} className="patient-detail-charts">
            <Grid
              style={
                !centralStore.bpChartDataValue ||
                !centralStore.bpChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Blood pressure / Mean arterial pressure
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <BloodPressureChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.pulseChartDataValue.data ||
                !centralStore.pulseChartDataValue.data.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Pulse
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <HumanPulseChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.o2SaturationChartDataValue ||
                !centralStore.o2SaturationChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  O2 Saturation/Pulse
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <O2SaturationPulseChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.temperatureChartDataValue ||
                !centralStore.temperatureChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Temperature
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <TemperatureChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div id="chartImg2">
          <Grid container spacing={3} className="patient-detail-charts">
            <Grid
              style={
                !centralStore.glucoseChartDataValue ||
                !centralStore.glucoseChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Glucose
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <GlucoseChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}{" "}
                </Box>
              </Paper>
            </Grid>
            <Grid
              style={
                !centralStore.activityChartDataValue ||
                !centralStore.activityChartDataValue.length
                  ? { display: "none" }
                  : {}
              }
              item
              xs={6}
            >
              <Paper className="shadow-box">
                <Box className="chart-header" p={2}>
                  Activity
                </Box>
                <Box className="chart-box" px={2} pb={2}>
                  {humanId && humanId !== "N/A" ? (
                    <ActivityChart humanId={humanId} />
                  ) : (
                    <h1>No Readings received for this user chart...!</h1>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  onInputChange = (event) => {
    //console.log("event.target.value", event.target.value);
    let input = event.target.value;
    let ids = input.split(",");
    this.setState({ emailIDs: ids }, function() {});
  };

  render() {
    if (this.state.openPopup === true) {
      return (
        <div>
          <Dialog
            open={this.state.openPopup}
            aria-labelledby="form-dialog-title"
            width={50}
            fullWidth={true}
          >
            <DialogTitle id="form-dialog-title" color="primary">
              Send Email
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Please Enter Email Address</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="emailpdf"
                label="Email Address"
                type="email"
                fullWidth={true}
                onChange={this.onInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.sendPdf} color="primary">
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    const { centralStore } = this.props;
    return (
      <Dialog
        fullScreen
        open={centralStore.currentOpenReportDialogValue}
        onClose={this.handleReportDialogClose}
        className="report-pdf"
      >
        <Container maxWidth="md">
          <div className="report-pdf-header">
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <button
                  className="btn btn-primary"
                  onClick={this.printDocument}
                >
                  Download PDF
                </button>
              </Box>
              <Box mr={2}>
                <button
                  className="btn btn-primary"
                  id="document"
                  onClick={this.printDocFile}
                >
                  Download DOC
                </button>
              </Box>{" "}
              <Box mr={2}>
                <button
                  className="btn btn-primary"
                  onClick={this.handleClickOpen}
                >
                  Download and Send Email
                </button>
              </Box>
            </Box>
            <span
              className="btn-close-report"
              onClick={this.handleReportDialogClose}
              aria-label="close"
            >
              <i className="fas fa-times"></i>
            </span>
          </div>

          {centralStore.numberOfVisibleCharts > 4
            ? this.twoImagesComponent()
            : this.oneImageComponent()}

          {this.state.successMessage ? (
            <Snackbar
              // anchorOrigin={{top,center}}
              autoHideDuration={6000}
              open={true}
              onClose={this.handleClose}
            >
              {this.state.successMessage ? (
                <Alert severity="success" onClose={this.handleClose}>
                  {this.state.successMessage}
                </Alert>
              ) : null}
            </Snackbar>
          ) : null}
        </Container>
      </Dialog>
    );
  }
}

export default inject("centralStore")(observer(ReportDialog));
