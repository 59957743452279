import React, { Component } from "react";
import {
  ArgumentAxis,
  Legend,
  Series,
  Chart,
  Tooltip,
  ValueAxis,
  ConstantLine,
  CommonSeriesSettings,
  Point,
} from "devextreme-react/chart";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import { observer, inject } from "mobx-react";
class GlucoseChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      activityTabValue: 0,
      dataType: "value",
      minValue: 33,
      maxValue: 40,
    };
  }
  activityTabValueChange = (event, newValue) => {
    const { centralStore } = this.props;

    this.setState({ activityTabValue: newValue });
    if (newValue === 0) {
      this.setState({
        dataType: "value",
        title:
          "Showing " +
          centralStore.temperatureChartDataValue.length +
          " Celsius Readings",
        minValue: 33,
        maxValue: 40,
      });
    } else if (newValue === 1) {
      this.setState({
        dataType: "valueF",
        title:
          "Showing " +
          centralStore.temperatureChartDataValue.length +
          " Fahrenheit Readings",
        minValue: 90,
        maxValue: 110,
      });
    }
  };

  customizeTooltip = (pointInfo) => {
    console.log("pointInfo", pointInfo);
    return {
      text: `Date: ${pointInfo.argumentText}<br/>Value: ${pointInfo.valueText}<br/>Time: ${pointInfo.point.data.time.slice(11,16)}`
    };
  };
  render() {
    const { centralStore } = this.props;

    return (
      <div>
        <>
          {centralStore.temperatureChartDataValue &&
          centralStore.temperatureChartDataValue.length ? (
            <div>
              <Paper>
                <Tabs
                  value={this.state.activityTabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.activityTabValueChange}
                  className="chart-tabs"
                >
                  <Tab label="Celsius" />
                  <Tab label="Fahrenheit" />
                </Tabs>
              </Paper>
              <Chart
                adjustOnZoom={true}
                adaptiveLayout={{
                  keepLabels: false,
                  height: 110,
                }}
                size={
                  this.props.viewedFromTable
                    ? { height: 210, width: 600 }
                    : { height: 210 }
                }
                tooltip={{
                  enabled: true,
                  customizeTooltip: this.customizeTooltip,
                }}
                dataSource={centralStore.temperatureChartDataValue}
              >
                <CommonSeriesSettings
                  argumentField="timestamp"
                  type="spline"
                  color="#a9c470"
                >
                  <Point size={7} visible={true} />
                </CommonSeriesSettings>
                <Series
                  name="Temperature"
                  valueField={this.state.dataType}
                  color="#a9c470"
                />

                <ArgumentAxis
                  color="#000000"
                  width={2}
                  title={
                    this.state.title
                      ? this.state.title
                      : "Showing " +
                        centralStore.temperatureChartDataValue.length +
                        " Temperature Readings"
                  }
                ></ArgumentAxis>

                <ValueAxis
                  width={2}
                  color="#000000"
                  tick={{ visible: true }}
                  tickInterval={10}
                  // title={{ text: "Arterial Pressure", wordWrap: true }}
                  showZero={true}
                >
                  <ConstantLine
                    width={1}
                    value={this.state.minValue}
                    label={{ visible: false }}
                    color="#f64866"
                  ></ConstantLine>
                  <ConstantLine
                    width={1}
                    value={this.state.maxValue}
                    label={{ visible: false }}
                    color="#f64866"
                  ></ConstantLine>
                </ValueAxis>
                <Legend
                  font={{ size: 16 }}
                  visible={true}
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                  position="outside"
                />
                <Tooltip enabled={true} />
              </Chart>
            </div>
          ) : (
            <h2>No data fetched. Consider Changing filter..!</h2>
          )}
        </>
      </div>
    );
  }
}
export default inject("centralStore")(observer(GlucoseChart));
