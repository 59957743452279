import React, { Component } from "react";
import logo from "../../assets/images/MVS-Logo-dark.png";
import TextField from "@material-ui/core/TextField";
import { Auth } from "aws-amplify";
import Footer from "../Footer";
import { withRouter, Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../common/util";
document.body.classList.add("bodyStyle");

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: undefined,
      password: undefined,
      errorMessage: null,
      loading: false,
    };
  }

  loginClick = async (event) => {
    event.preventDefault();
    const { centralStore } = this.props;
    console.log("Error Message===>", this.state.errorMessage);
    if (!this.state.errorMessage) {
      centralStore.showAuthLoader();
      await Auth.signIn(this.state.username, this.state.password)
        .then((userResponse) => {
          localStorage.setItem(
            "sessionData",
            JSON.stringify(userResponse.signInUserSession)
          );
          centralStore.setCurrentUser(userResponse);
          centralStore.setUserAuthenticated(true);
          centralStore.hideAuthLoader();
          this.props.history.push("/home");
        })
        .catch((error) => {
          console.log(error.message);
          if (this.state.username && !this.state.password) {
            this.setState({ errorMessage: "Password Cannot be Empty" });
          } else {
            this.setState({ errorMessage: error.message });
          }
          centralStore.hideAuthLoader();
        });
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      errorMessage: null,
    });
  };

  AuthorizeUserCheck = async () => {
    const { centralStore } = this.props;
    centralStore.showAuthLoader();
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((authenticatedUser) => {
        localStorage.setItem(
          "sessionData",
          JSON.stringify(authenticatedUser.signInUserSession)
        );
        if (authenticatedUser) {
          console.log("authenticatedUser",authenticatedUser)
          centralStore.setCurrentUser(authenticatedUser);
          console.log("window.location.pathname", window.location.pathname);
          centralStore.setUserAuthenticated(true);
          if (
            window.location.pathname === "/" ||
            window.location.pathname === "/login"
          ) {
            this.props.history.push("/home");
            centralStore.hideAuthLoader();
          } else {
            this.props.history.push(window.location.pathname);
            centralStore.hideAuthLoader();
          }
        }
      })
      .catch((error) => {
        console.log("error",error)
        centralStore.hideAuthLoader();
        console.log("Current Auth User===> Error");
        localStorage.clear();
        this.props.history.push("/");
        centralStore.setCurrentUser(null);
        centralStore.setUserAuthenticated(false);
      });
  };

  componentDidMount = () => {
    console.log("Didmount Login");
    const hostname = window && window.location && window.location.hostname;
    if (hostname.includes("localhost") || hostname.includes("dev")) {
      this.setState({
        username: "mvsstaff1234@gmail.com",
        password: "Admin@1234",
      });
    }
    this.AuthorizeUserCheck();
 };


  render() {
    const { classes, centralStore } = this.props;

    return (
      <>
        <Backdrop
          className={classes.backdrop}
          open={centralStore.currentAuthloaderValue}
          onClick={() => {}}
        >
          <CircularProgress className={classes.backdrop} color="inherit" />
        </Backdrop>
        <div className="login-wrapper">
          <div className="login-box-outer">
            <a href="/" className="login-logo">
              <img src={logo} alt="" className="img-fluid" />
            </a>
            <div className="login-box white-radius-box">
              <h5 className="box-heading text-center">Log In</h5>
              {this.state.errorMessage ? (
                <div className="alert alert-danger">
                  {this.state.errorMessage}{" "}
                </div>
              ) : null}
              <div className="login-fields">
                <div className="form-field">
                  <TextField
                    label="Email"
                    fullWidth={true}
                    type="username"
                    id="username"
                    value={this.state.username || ""}
                    onChange={this.onInputChange}
                  />
                </div>
                <div className="form-field">
                  <TextField
                    label="Password"
                    fullWidth={true}
                    type="password"
                    id="password"
                    value={this.state.password || ""}
                    onChange={this.onInputChange}
                  />{" "}
                </div>
                <div className="form-field text-center">
                  <Link to="/forgotpassword" className="link-forgot-password">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div>
                <button className="btn btn-primary" onClick={this.loginClick}>
                  Log In
                </button>
              </div>
              {/* {Auth.currentAuthenticatedUser.authenticatedUser.signInUserSession.idToken.payload['custom:role'] === 'admin' ? 
              <p className="already-member" >
                Not a member?
                <Link to="/signup" >Sign Up</Link>
              </p>  } */}
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}
export default withRouter(
  withStyles(styles)(inject("centralStore")(observer(Login)))
);
