import React from "react";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { getDateDaysBefore } from "../../common/util";
import { observer, inject } from "mobx-react";
import {
  addNote,
  getNotesById,
  updateNote,
  deleteNote,
} from "../../common/apicall/notes";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class ClinicNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      notes: "",
      physicanName: "",
      patient_id: "",
      columns: [
        {
          title: "Date",
          field: "date",
          editable: "never",
          emptyValue: getDateDaysBefore(0),
        },
        {
          title: "Physician Name",
          field: "physicianName",
          editable: "never",
          emptyValue: this.props.currentUserInfo.name,
        },
        {
          title: "Notes",
          field: "notes",
          emptyValue: "emptyValue",
        },
      ],
      data: [],
    };
  }

  componentDidMount = () => {
    let patient_id = localStorage.getItem("patient_id");
    getNotesById(patient_id).then((response) => {
      console.log("response Notes===>", response);
      this.setState({ data: response, patient_id: patient_id });
    });
  };

  render() {
    const { centralStore } = this.props;
    const { firstName, lastName } = centralStore.selectedPatientDataValue;
    return (
      <div className="content">
        <div className="tbl-clinic-notes">
          <MaterialTable
            title={`Patient Notes of ${firstName} ${lastName} ` || "NA"}
            icons={tableIcons}
            columns={this.state.columns}
            data={this.state.data}
            options={{ search: false, paging: false, actionsColumnIndex: 3 }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  resolve();
                  this.setState((prevState) => {
                    const data = [...prevState.data];
                    newData.date = getDateDaysBefore(0);
                    newData.physicianName = this.props.currentUserInfo.name;
                    newData.patient_id = this.state.patient_id;
                    data.push(newData);
                    addNote(newData).then((response) => {
                      console.log("Response Add Note=>>>>", response);
                    });
                    return { ...prevState, data };
                  });
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      this.setState((prevState) => {
                        const data = [...prevState.data];
                        let index = data.indexOf(oldData);
                        data[index] = newData;
                        newData.date = getDateDaysBefore(0);
                        newData.physicianName = this.props.currentUserInfo.name;
                        newData.patient_id = this.state.patient_id;
                        newData.timestamp = this.state.data[index].timestamp;
                        updateNote(newData).then((response) => {
                          console.log("Update Response", response);
                        });
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    this.setState((prevState) => {
                      const data = [...prevState.data];
                      let index = data.indexOf(oldData);
                      data.splice(index, 1);

                      let noteData = {
                        patient_id: this.state.patient_id,
                        timestamp: this.state.data[index].timestamp,
                      };
                      deleteNote(noteData).then((response) => {
                        console.log("Delete Resp", response);
                        this.setState({ data: data });
                      });

                      return { ...prevState, data };
                    });
                  }, 600);
                }),
            }}
          />
        </div>
      </div>
    );
  }
}
export default inject("centralStore")(observer(ClinicNotes));
