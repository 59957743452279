import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import BloodPressureChart from "../charts/BloodPressureChart";
import HumanPulseChart from "../charts/HumanPulseChart";
import O2SaturationPulseChart from "../charts/O2SaturationPulseChart";
import ActivityChart from "../charts/ActivityChart";
import { observer, inject } from "mobx-react";
import GlucoseChart from "../charts/GlucoseChart";
import TemperatureChart from "../charts/TemperatureChart";
import EmergencyInfo from "./EmergencyInfo";
class PatientCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notesData: [],
    };
  }

  render() {
    const { centralStore } = this.props;
    const { humanId } = centralStore.selectedPatientDataValue;

    return (
      <div>
        <Grid container spacing={3} className="patient-detail-charts">
          <Grid
            style={
              !centralStore.bpChartDataValue ||
              !centralStore.bpChartDataValue.length
                ? { display: "none" }
                : {}
            }
            item
            xs={12} md={6} lg={4}
          >
            <Paper className="shadow-box">
              <Box className="chart-header" p={2}>
                Blood pressure / Mean arterial pressure
              </Box>
              <Box className="chart-box" px={2} pb={2}>
                {humanId && humanId !== "N/A" ? (
                  <BloodPressureChart humanId={humanId} />
                ) : (
                  <h1>No Readings received for this user chart...!</h1>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid
            style={
              !centralStore.pulseChartDataValue.data ||
              !centralStore.pulseChartDataValue.data.length
                ? { display: "none" }
                : {}
            }
            item
            xs={12} md={6} lg={4}
          >
            <Paper className="shadow-box">
              <Box className="chart-header" p={2}>
                Pulse
              </Box>
              <Box className="chart-box" px={2} pb={2}>
                {humanId && humanId !== "N/A" ? (
                  <HumanPulseChart humanId={humanId} />
                ) : (
                  <h1>No Readings received for this user chart...!</h1>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid
            style={
              !centralStore.o2SaturationChartDataValue ||
              !centralStore.o2SaturationChartDataValue.length
                ? { display: "none" }
                : {}
            }
            item
            xs={12} md={6} lg={4}
          >
            <Paper className="shadow-box">
              <Box className="chart-header" p={2}>
                O2 Saturation/Pulse
              </Box>
              <Box className="chart-box" px={2} pb={2}>
                {humanId && humanId !== "N/A" ? (
                  <O2SaturationPulseChart humanId={humanId} />
                ) : (
                  <h1>No Readings received for this user chart...!</h1>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid
            style={
              !centralStore.temperatureChartDataValue ||
              !centralStore.temperatureChartDataValue.length
                ? { display: "none" }
                : {}
            }
            item
            xs={12} md={6} lg={4}
          >
            <Paper className="shadow-box">
              <Box className="chart-header" p={2}>
                Temperature
              </Box>
              <Box className="chart-box" px={2} pb={2}>
                {humanId && humanId !== "N/A" ? (
                  <TemperatureChart humanId={humanId} />
                ) : (
                  <h1>No Readings received for this user chart...!</h1>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid
            style={
              !centralStore.glucoseChartDataValue ||
              !centralStore.glucoseChartDataValue.length
                ? { display: "none" }
                : {}
            }
            item
            xs={12} md={6} lg={4}
          >
            <Paper className="shadow-box">
              <Box className="chart-header" p={2}>
                Glucose
              </Box>
              <Box className="chart-box" px={2} pb={2}>
                {humanId && humanId !== "N/A" ? (
                  <GlucoseChart humanId={humanId} />
                ) : (
                  <h1>No Readings received for this user chart...!</h1>
                )}{" "}
              </Box>
            </Paper>
          </Grid>
          <Grid
            style={
              !centralStore.activityChartDataValue ||
              !centralStore.activityChartDataValue.length
                ? { display: "none" }
                : {}
            }
            item
            xs={12} md={6} lg={4}
          >
            <Paper className="shadow-box">
              <Box className="chart-header" p={2}>
                Activity
              </Box>
              <Box className="chart-box" px={2} pb={2}>
                {humanId && humanId !== "N/A" ? (
                  <ActivityChart humanId={humanId} />
                ) : (
                  <h1>No Readings received for this user chart...!</h1>
                )}
              </Box>
            </Paper>
           
          </Grid>
          <Grid item
            xs={12} md={6} lg={4}
          >
            <Paper className="shadow-box">
              <Box className="chart-header" p={2}>
                <h2 fontSize="20" fontWeight="90" >
                  EMERGENCY PURPOSES
                </h2>
              </Box>
              <Box className="chart-box" px={2} pb={2}>
                <EmergencyInfo {...this.props} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default inject("centralStore")(observer(PatientCharts));
