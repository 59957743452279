import * as React from "react";

import {
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Series,
  Chart,
  Tooltip,
  ValueAxis,
  ConstantLine,
  Point,
} from "devextreme-react/chart";
import { observer, inject } from "mobx-react";

class BloodPressureChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
    };
  }

  customizeTooltip = (pointInfo) => {
    console.log("pointInfo", pointInfo);

    return {
      text: `Date: ${pointInfo.argumentText}<br/>Value: ${pointInfo.valueText}<br/>Time: ${pointInfo.point.data.time.slice(11,16)}`
    };
  };
  render() {
    const { centralStore } = this.props;

    return (
      <>
        {centralStore.bpChartDataValue &&
        centralStore.bpChartDataValue.length ? (
          <>
            <Chart
              adjustOnZoom={true}
              onPointClick={this.onPointClick}
              adaptiveLayout={{
                keepLabels: false,
                height: 100,
              }}
              size={
                this.props.viewedFromTable
                  ? { height: 210, width: 400 }
                  : { height: 210 }
              }
              tooltip={{
                enabled: true,
                customizeTooltip: this.customizeTooltip,
              }}
              dataSource={centralStore.bpChartDataValue}
            >
              <CommonSeriesSettings
                argumentField="timestamp"
                type="spline"
                color="#a9c470"
              >
                <Point size={7} visible={true} />
              </CommonSeriesSettings>
              <Series
                name="Diastolic(mmHg)"
                valueField="diastolic"
                color="#a9c470"
              />

              <Series
                name="Systolic(mmHg)"
                valueField="systolic"
                color="#527fbb"
              />

              <ArgumentAxis
                color="#000000"
                width={2}
                title={
                  "Showing " +
                  centralStore.bpChartDataValue.length +
                  " BP Readings"
                }
                valueMarginsEnabled={false}
              ></ArgumentAxis>

              <ValueAxis
                width={2}
                color="#000000"
                tick={{ visible: true }}
                tickInterval={10}
                // title={{ text: "Arterial Pressure", wordWrap: true }}
                showZero={true}
              >
                <ConstantLine
                  width={2}
                  value={91}
                  label={{ visible: false }}
                  color="#527fbb"
                ></ConstantLine>
                <ConstantLine
                  width={2}
                  value={139}
                  label={{ visible: false }}
                  color="#527fbb"
                ></ConstantLine>
                <ConstantLine
                  width={2}
                  value={51}
                  label={{ visible: false }}
                  color="#a9c470"
                ></ConstantLine>
                <ConstantLine
                  width={2}
                  value={98}
                  label={{ visible: false }}
                  color="#a9c470"
                ></ConstantLine>
              </ValueAxis>
              <Legend
                font={{ size: 16 }}
                visible={true}
                verticalAlignment="bottom"
                horizontalAlignment="center"
                position="outside"
              />
              <Tooltip enabled={true} />
            </Chart>
          </>
        ) : (
          <h2>No data fetched. Consider Changing filter..!</h2>
        )}
      </>
    );
  }
  componentWillUnmount = () => {
    this.setState({ data: [] });
  };
}
export default inject("centralStore")(observer(BloodPressureChart));
