import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { observer, inject } from "mobx-react";
import { getPaientAggregateReadings } from "../../common/apicall/patient";
export class ReadingPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentDidMount = () => {
    getPaientAggregateReadings().then((data) => {
      console.log("Readings Data==>", data);
      this.setState({ data: data });
    });
  };
  render() {
    const { centralStore } = this.props;
    const {
      meanMAP,
      meanSBP,
      meanDBP,
      meanHR,
      minMAP,
      minSBP,
      minDBP,
      minHR,
      maxMAP,
      maxDBP,
      maxSBP,
      maxHR,
    } = this.state.data;
    return (
      <div>
        <div className="reading-popup">
          <div className="reading-popup-body">
            <div className="reading-popup-content">
              <Box component="h3" mb={2} className="reading-popup-heading">
                BP/HR/MAP Readings
              </Box>
              <button
                className="close-btn"
                onClick={() => {
                  centralStore.setShowReadingPopup(false);
                }}
              >
                <i className="icon-cancel"></i>
              </button>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                  <Paper className="gray-box">
                    <Box component="h4" mb={2}>
                      Blood Pressure
                    </Box>
                    <Box display="flex">
                      <Box display="flex" flexGrow="1" flexDirection="column">
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Mean SBP</label>
                            <div className="value">{meanSBP}</div>
                          </div>
                          <div className="reading-values-box">
                            <label>Mean DBP</label>
                            <div className="value">{meanDBP}</div>
                          </div>
                        </div>
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Max SBP</label>
                            <div className="value">{maxSBP}</div>
                          </div>
                          <div className="reading-values-box">
                            <label>Max DBP</label>
                            <div className="value">{maxDBP}</div>
                          </div>
                        </div>
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Min SBP</label>
                            <div className="value">{minSBP}</div>
                          </div>
                          <div className="reading-values-box">
                            <label>Min DBP</label>
                            <div className="value">{minDBP}</div>
                          </div>
                        </div>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className="reading-icon"
                      >
                        <i className="icon-sphygmomanometer"></i>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Paper className="gray-box">
                    <Box component="h4" mb={2}>
                      Heart Rate
                    </Box>
                    <Box display="flex">
                      <Box display="flex" flexGrow="1" flexDirection="column">
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Mean Heart Rate</label>
                            <div className="value">{meanHR}</div>
                          </div>
                        </div>
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Max Heart Rate</label>
                            <div className="value">{maxHR}</div>
                          </div>
                        </div>
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Min Heart Rate</label>
                            <div className="value">{minHR}</div>
                          </div>
                        </div>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className="reading-icon"
                      >
                        <i className="icon-electrocardiogram"></i>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Paper className="gray-box">
                    <Box component="h4" mb={2}>
                      MAP{" "}
                    </Box>
                    <Box display="flex">
                      <Box display="flex" flexGrow="1" flexDirection="column">
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Mean MAP</label>
                            <div className="value">{meanMAP}</div>
                          </div>
                        </div>
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Max MAP</label>
                            <div className="value">{maxMAP}</div>
                          </div>
                        </div>
                        <div className="reading-values">
                          <div className="reading-values-box">
                            <label>Min MAP</label>
                            <div className="value">{minMAP}</div>
                          </div>
                        </div>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className="reading-icon"
                      >
                        <i className="icon-blood-pressure-1"></i>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("centralStore")(observer(ReadingPopup));
