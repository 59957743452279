import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PatientDetails from "../PatientDetails";
import DateFilter from "../DateFilter";
import { observer, inject } from "mobx-react";
import { setPatientTableWithFilter } from "../../common/api-utils";
import { getFormattedDate, getDateDaysBefore } from "../../common/util";
import AddEditPatientDialog from "./AddEditPatientDialog";
document.body.classList.remove("bodyStyle");

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      patientData: [],
      openDialog: false,
      firstName: "",
      lastName: "",
      dob: "01/01/2001",
      weight: "",
      provider: "",
      phone: "",
      age: "",
      email: "",
      addPatientError: false,
    };
  }

  handleDialogClickOpen = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      openDialog: false,
      firstName: "",
      lastName: "",
      dob: "01/01/2001",
      weight: "",
      provider: "",
      phone: "",
      age: "",
      email: "",
      addPatientError: false,
    });
  };

  componentDidMount = async () => {
    const { centralStore } = this.props;
    localStorage.setItem("lastevaluatedkey", JSON.stringify({}));
    let filterValue = {
      startDate: getDateDaysBefore(30),
      endDate: getDateDaysBefore(0),
    };
    await setPatientTableWithFilter(filterValue);
    centralStore.setNumberOfVisibleCharts(0);
  };

  render() {
    const { centralStore } = this.props;
    let firstName = this.props.currentUserInfo.name
      ? this.props.currentUserInfo.name.split(" ")[0]
      : "";
    let lastName = this.props.currentUserInfo.name
      ? this.props.currentUserInfo.name.split(" ")[1]
      : "";

    return (
      <div className="content">
        <Box
          display="flex"
          justifyContent="space-between"
          className="page-header"
        >
          <Typography variant="h3" className="page-name">
            Home
          </Typography>
          <DateFilter />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          className="patient-and-doc-info"
        >
          <Box className="info-section">
            <label>Doctor Name</label>
            <Typography variant="h5" className="doc-patient-name">
              {firstName} {lastName}
            </Typography>
          </Box>
          <Box textAlign="right" className="info-section">
            <Box component="p">
              <label>Date : </label> {getFormattedDate(new Date(), true)}
            </Box>
          </Box>
        </Box>
        <Box textAlign="right" mb={2}>
          <button
            style={{ display: "none" }}
            className="btn btn-primary"
            onClick={() => {
              centralStore.setSelectedPatientData({
                firstName: "",
                lastName: "",
                patient_id: "",
                phone: "",
                email: "",
                provider: { name: "", email: "" },
              });
              centralStore.openMessageDialog();
            }}
          >
            Broadcast Message
          </button>{" "}
          <button
            className="btn btn-primary"
            onClick={() => {
              centralStore.showAddEditDialog();
            }}
          >
            Add Patient
          </button>
        </Box>
        <PatientDetails />
        {centralStore.currentopenDialogValue ? (
          <AddEditPatientDialog {...this.props} />
        ) : null}
      </div>
    );
  }
}

export default inject("centralStore")(observer(Home));
