import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DateFilter from "../DateFilter";
import { observer, inject } from "mobx-react";
import { setCurrentPatientData, setChartData } from "../../common/api-utils";
import { withRouter } from "react-router-dom";
import { getDateDaysBefore } from "../../common/util";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ClinicNotes from "./ClinicNotes";
import PatientCharts from "./PatientCharts";
import PatientDocInfo from "./PatientDocInfo";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import ReportDialog from "./ReportDialog";
import ScrollArea from "react-scrollbar";
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import {archivePatient} from '../../common/apicall/patient';
import Snackbar from '@material-ui/core/Snackbar';
import Button from "@material-ui/core/Button";
import ArchiveIcon from '@material-ui/icons/Archive';
import Alert from "@material-ui/lab/Alert/Alert";

document.body.classList.remove("bodyStyle");

class PatientDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      patientName: "",
      openNotes: false,
      openReportDialog: false,
      notesData: [],
      openArchive:false,
    };
  }
  componentDidMount = async () => {
    await setCurrentPatientData();
    let filter = {
      startDate: getDateDaysBefore(30),
      endDate: getDateDaysBefore(0),
    };
    await setChartData(filter);
  };
  handleNotesDialogClose = () => {
    this.setState({ openNotes: false });
  };
  
  handleArchiveDialogclose=()=>{
    this.setState({openArchive:false});
  }

  handleMessageDialogOpen = () => {
    const { centralStore } = this.props;
    centralStore.openMessageDialog();
    console.log("openMessageDialogValue", centralStore.openMessageDialogValue);
  };
  handleMessageDialogClose = () => {
    const { centralStore } = this.props;
    centralStore.closeMessageDialog();
  };

  handleArchiveInactive = (patient) => {
    const { centralStore } = this.props;
    this.setState({ openPopup: true });
    this.setState({ successMessage: 'Patient Archived Successfully!' });
    //   window.location.reload();
  
    let patientData = {
        patient_id: patient.patient_id,
        physician_id: patient.physician_id,
    };
  
    archivePatient(patientData).then((response) => {
        console.log('Delete Resp', response);
        centralStore.hideLoader();
        this.props.history.push('/home');
    });
};

  handleReportClick = () => {
    const { centralStore } = this.props;
    centralStore.showReportDialog();
    console.log("Open", centralStore.currentOpenReportDialogValue);
  };

  handleScheduleClick = () => {
    const { centralStore } = this.props;
    centralStore.openScheduleDialog();
  };

  render() {
    const { centralStore } = this.props;
    console.log("numberOfVisibleCharts", centralStore.numberOfVisibleCharts);
    return (
        <ScrollArea
          speed={0.8}
          className="area"
          contentClassName="content"
          horizontal={false}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            className="page-header"
          >
            <Typography variant="h3" className="page-name">
              Patient Dashboard
            </Typography>
            <DateFilter />
          </Box>
          <Box className="patient-and-doc-info">
            <PatientDocInfo />
            <Box my={1} display="flex" alignItems="center" className="chart-action-buttons">
              <button
                onClick={() => {
                  centralStore.setShowReadingPopup(true);
                }}
                className="icon-btn"
              >
                <i className="icon-dashboard"></i>
                <span>BP/HR/MAP Reading</span>
              </button>
              <button className="icon-btn">
                <i className="icon-pill"></i>
                <span>Reminder</span>
              </button>
        
              <button
                className="icon-btn"
                onClick={() => {
                  this.setState({ openNotes: true });
                }}
              >
                <i className="icon-report"></i>
                <span>Clinic Note</span>
              </button>
            
              <button
                onClick={(event) => {
                  this.handleMessageDialogOpen(event);
                }}
                className="icon-btn"
              >
                <MailOutlineOutlinedIcon />
                <span>Email &amp; SMS</span>
              </button>{" "}
              <button className="icon-btn" onClick={(event) => this.handleScheduleClick(event)}>
                <ForumOutlinedIcon />
                <span>Schedule Message</span>
              </button>
              <button className="icon-btn" onClick={this.handleReportClick}>
                <i className="icon-insurance"></i>
                <span>Report</span>
              </button>
              
              <button className="icon-btn" onClick={() => {
                  centralStore.setShowAlarmPopup(true);
                }}>
                <i className="icon-insurance"></i>
                <span>Alarm</span>
              </button>
              
              <button className="icon-btn show-on-mobile"  onClick={() => {
                  this.setState({ openArchive: true });
                }}>
               <ArchiveIcon />
                <span>Archive</span>
              </button>
              
            </Box>
          </Box>
          <PatientCharts />
          <Dialog
            open={this.state.openNotes}
            onClose={this.handleNotesDialogClose}
            maxWidth="lg"
            fullWidth={true}
          >
            <DialogContent className="sms-tabs">
              <ClinicNotes {...this.props} />
            </DialogContent>
          </Dialog>
          <ReportDialog {...this.props} />
           
          <Box className='floating-button'>
            <Button  onClick={() => {
                        this.setState({ openArchive: true });
                      }}>Archive
            <ArchiveIcon />
            </Button> 
         
            <Dialog className='confirm-popup'
            open={this.state.openArchive}
            onClose={this.handleArchiveDialogclose}
            >
             <DialogContent>
             <DialogContentText>
              <Typography>
               Are you sure you want to archive?
              </Typography>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
           <Button onClick={(event) => {
               this.handleArchiveInactive(centralStore.selectedPatientData);
               centralStore.showLoader();
               this.setState({ openArchive: false });
            }}  className='btn-primary' variant="contained"
           >
            Yes
          </Button>
          <Button onClick={this.handleArchiveDialogclose} variant="contained" 
                  color="secondary"  className='btn-red' autoFocus>
            No
          </Button>
          </DialogActions>
          </Dialog>
          </Box>
          <div className='alert-section'>            
                       {" "}
                            {this.state.openPopup ? (
                              <Snackbar
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "center"
                                }}
                                autoHideDuration={6000}
                                open={true}
                                onClose={this.handlePopupClose}
                              >
                                {this.state.openPopup ? (
                                  <Alert variant="filled"
                                    severity="success" onClose={this.handlePopupClose}>
                                    {this.state.successMessage}
                                  </Alert>
                                ) : null}
                              </Snackbar>
                            ) : null}
                            </div>
                           
        </ScrollArea>
    );
  }
}

export default withRouter(inject("centralStore")(observer(PatientDashboard)));
