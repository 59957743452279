import React, { Component } from "react";
import {
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Series,
  Chart,
  Tooltip,
  ValueAxis,
  ConstantLine,
  Point,
} from "devextreme-react/chart";

import { observer, inject } from "mobx-react";

class GlucoseChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
    };
  }

  customizeTooltip = (pointInfo) => {
    console.log("pointInfo", pointInfo);
    return {
      text: `Date: ${pointInfo.argumentText}<br/>Value: ${pointInfo.valueText}<br/>Time: ${pointInfo.point.data.time.slice(11,16)}`
    };
  };

  render() {
    const { centralStore } = this.props;
    return (
      <div>
        <>
          {centralStore.glucoseChartDataValue &&
          centralStore.glucoseChartDataValue.length ? (
            <Chart
              adjustOnZoom={true}
              adaptiveLayout={{
                keepLabels: false,
                height: 100,
              }}
              size={
                this.props.viewedFromTable
                  ? { height: 210, width: 400 }
                  : { height: 210 }
              }
              tooltip={{
                enabled: true,
                customizeTooltip: this.customizeTooltip,
              }}
              dataSource={centralStore.glucoseChartDataValue}
            >
              <CommonSeriesSettings
                argumentField="timestamp"
                type="line"
                color="#a9c470"
              >
                <Point size={7} visible={true} />
              </CommonSeriesSettings>
              <Series name="Glucose" valueField="value" color="#a9c470" />

              <ArgumentAxis
                color="#000000"
                width={2}
                title={
                  "Showing " +
                  centralStore.glucoseChartDataValue.length +
                  " Glucose Readings"
                }
                valueMarginsEnabled={false}
              ></ArgumentAxis>

              <ValueAxis
                width={2}
                color="#000000"
                tick={{ visible: true }}
                tickInterval={10}
                // title={{ text: "Arterial Pressure", wordWrap: true }}
                showZero={true}
              >
                <ConstantLine
                  width={1}
                  value={70}
                  label={{ visible: false }}
                  color="#527fbb"
                ></ConstantLine>
                <ConstantLine
                  width={1}
                  value={100}
                  label={{ visible: false }}
                  color="#527fbb"
                ></ConstantLine>
              </ValueAxis>
              <Legend
                font={{ size: 16 }}
                visible={true}
                verticalAlignment="bottom"
                horizontalAlignment="center"
                position="outside"
              />
              <Tooltip enabled={true} />
            </Chart>
          ) : (
            <h2>No data fetched. Consider Changing filter..!</h2>
          )}
        </>
      </div>
    );
  }
}
export default inject("centralStore")(observer(GlucoseChart));
